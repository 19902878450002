import styled from "styled-components";

export const Tiles = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`
export const Tile = styled.div`
  background-color: ${({ theme }) => theme.background};
  border-radius: 4px;
  width: 100%;
  padding: 20px;
  border: 2px solid ${ props => props.promoted ? "var(--main)" : "transparent"};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 15px;
  position: relative;
  &:nth-child(1) { 
    margin-left: 0;
  }
`
export const DeskPlanDetails = styled.div`
`
export const DeskPlanHeader = styled.div`

`

export const PlanPerk = styled.li`
  position: relative;
  list-style: none;
  padding: 8px 0 8px 10px;
  opacity: ${ props => props.unactive ? '.4' : '1' };
  &:before {
    content: "";
    display: inline-block;
    height: 8px;
    width: 20px;
    border-left: 3px solid var(--main);
    border-bottom: 3px solid var(--main);
    position: absolute;
    left: -20px;
    top: 13px;
    transform: rotate(-45deg);
  }
  &:after {
    content: "";
    display: inline-block;
    height: 20px;
    width: 20px;
    background-color: var(--main);
    opacity: .25;
    border-radius: 50px;
    position: absolute;
    left: -22px;
    top: 11px;
  }
`
export const TilePerks = styled.div`
  padding: 20px 0;
  margin: 20px 20px 0;
  border-top: 2px dashed rgba(0,0,0,.1);
  ul {
    padding-left: 20px;
  }
`
export const PlanPrice = styled.div`
  margin-top: 10px;
`
export const TileTitle = styled.div`
  padding: 20px;
`
export const TileSale = styled.div`
  position: absolute;
  top: -1px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--main);
  padding: 2px 16px;
  color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`
export const BackButtonWrap = styled.div`
  margin-bottom: 20px; 
  padding-bottom: 20px;
  border-bottom: 2px dashed rgba(0,0,0,0.1);
`
export const BackButton = styled.span`
  cursor: pointer;
  opacity: .6;
  i {
    margin-right: 10px;
  }
`
export const CalendarView = styled.div`
  padding-top: 20px;
  .MuiGrid-container {
    overflow-y: visible;
    border: 1px solid rgba(0,0,0,0.1);
   }
  div[class*="HorizontalViewLayout-stickyHeader"] {
    border-right: 1px solid rgba(0,0,0,0.1);
    border-bottom: 2px solid rgba(0,0,0,0.1);
    position: static;
  }
  div[class*="Toolbar-toolbar"] {
    border-bottom: none;
    button[class*="textButton"] {
      color: var(--main);
      font-size: 1.2em;
    }
  }
  div[class^="Cell-dayOfWeek"] {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 2px solid rgba(0,0,0,.1);
    font-size: 1.1em;
    text-transform: uppercase;
  }
  div[class*="Cell-today"] {
    margin-right: 8px;
    background-color: transparent;
    color: var(--main);
    font-weight: bold;
    opacity: 1;
  }
  div[class*="Cell-text"] {
    text-align: right;
    padding-bottom: 10px;
    opacity: .8;
  }
`

