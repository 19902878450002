import React, {Component} from 'react';
import {LHDatePickerWrap, LHInputWrap} from "../../theme/globalStyles";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pl from "date-fns/locale/pl";
registerLocale("pl", pl);

class FormDatePicker extends Component {
  render() {
    return (
        <LHDatePickerWrap>
          <LHInputWrap>
            <DatePicker
              selected={this.props.selected}
              onChange={this.props.onChange}
              timeFormat="HH:mm"
              locale="pl"
              dateFormat={this.props.showTimeSelect ? "dd-MM-yyyy HH:mm" : "dd-MM-yyyy"}
              showTimeSelect={this.props.showTimeSelect}
              placeholderText={this.props.placeholder}
            />
          </LHInputWrap>
        </LHDatePickerWrap>
    );
  }
}
export default FormDatePicker;
