import {connect} from "react-redux";
import {ChangePasswordPage} from "./ChangePassword.page";

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = dispatch => {
  return {

  }
};

export const ChangePasswordContainer = connect(mapStateToProps, mapDispatchToProps)(ChangePasswordPage);
