import React, {Component} from 'react';
import {MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBInput} from 'mdbreact';
import {connect} from "react-redux";
import {createRoomBooking, ROOM_ACTIONS} from "../Rooms.actions";
import PropTypes from "prop-types";
import {IMessages} from "../../../common/i18n/pl_PL";
import * as Styled from "./RoomBookingModal.style";
import {LHButton, LHButtonOutline, LHInputWrap} from "../../../theme/globalStyles";
import {stripePublicKey} from "../../../common/helpers/stripe";
import {selectRoomById} from "../Rooms.selectors";
import moment from "moment";
import SubmitSpinner from "../../Common/SubmitSpinner";
import Rooms from "../Rooms.list";
import {getPersonalStats} from "../../Calendar/Calendar.actions";
import {Redirect} from "react-router";

class RoomBookingModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      length: 0,
      error: null,
      period: '',
      availableRoomHours: 0,
      canUseFreeHours: false,
      successRedirect: false,
      notes: '',
    };

    this.submitForm = this.submitForm.bind(this);
  }

  async componentDidMount() {
    const {getPersonalStats, roomData: {id}} = this.props;
    await getPersonalStats();
    const room = Rooms.find(room => room.id === id);
    const roomHoursRatio = room.hoursRatio;

    const {personalStats: {availableRoomsHours}} = this.props;

    this.setState({availableRoomHours: Math.floor(availableRoomsHours / roomHoursRatio)});
  }

  setBookingLength(length) {
    const period = moment(this.props.booking.date).add(length, 'hours');
    const {availableRoomHours} = this.state;
    let canUseFreeHours = false;
    if (length <= availableRoomHours) {
      canUseFreeHours = true;
    }

    this.setState({
      canUseFreeHours,
      length: length,
      period: moment(period).format('HH:mm')
    });
  }

  async submitForm() {
    const {length, canUseFreeHours, notes} = this.state;
    const {booking: {date}, roomData: {id}, user: {email}} = this.props;

    if (!length) {
      this.setState({error: IMessages.LENGTH_NOT_SELECTED});
      return;
    }

    this.setState({submitting: true});

    try {
      const response = await this.props.createRoomBooking(id, date, length, notes);

      if (canUseFreeHours) {
        this.setState({successRedirect: true});
      } else {
        this.redirectToPayment(response.stripeSessionId);
      }
    } catch (e) {
      console.log(e.message);
      this.setState({
        submitting: false,
        error: e.message,
      });
    }
  }

  redirectToPayment(sessionId) {
    const stripe = window.Stripe(stripePublicKey);
    stripe.redirectToCheckout({
      sessionId,
    }).then(function (result) {
      console.error(result);
    });
  }

  getAvailableLengthOptions() {
    const {booking: {date}, roomData: {id}} = this.props;
    const selectedTime = moment(date);
    const lastTime = moment(moment(date).format('YYYY-MM-DD 18:00'));
    const diff = lastTime.diff(selectedTime);
    const diffDuration = moment.duration(diff);
    const options = [];
    for (let i = 1; i <= diffDuration.hours(); i++) {
      if(i === 1) {
        options.push(0.5)
      }
      options.push(i);
    }
    if(diffDuration.hours() === 0 && diffDuration.minutes() === 30) {
      options.push(0.5);
    }
    return options;
  }

  render() {
    const {booking: {date}, roomData: {name}, hideModal} = this.props;
    const {availableRoomHours, canUseFreeHours, length, successRedirect, notes} = this.state;
    const availableLengthOptions = this.getAvailableLengthOptions();

    if(successRedirect){
      return <Redirect to="/?roomBooking=success"/>;
    }

    return (
      <Styled.ModalWrap>
        <MDBModal isOpen={true} toggle={() => hideModal()} size="lg">
          <MDBModalHeader toggle={() => hideModal()}>{name}</MDBModalHeader>
          <MDBModalBody>
            <div style={{marginBottom: '20px'}}>
              Zarezerwuj salę na
              <Styled.ModalForm>
                <LHInputWrap validation={this.state.error}>
                  <select className="browser-default custom-select"
                          onChange={(e) =>
                            this.setBookingLength(e.currentTarget.value)
                          }
                  >
                    <option value="0">Wybierz</option>
                    {availableLengthOptions.map(option => <option value={option}
                                                                  key={`length-option-${option}`}>{option} godz.</option>)}
                  </select>
                </LHInputWrap>
              </Styled.ModalForm>
            </div>
            <p>Data: <strong>{moment(date).format('DD.MM.YYYY')}</strong></p>
            {!this.state.period &&
            <p>Godzina rozpoczęcia wynajmu: <strong>{moment(date).format('HH:mm')}</strong></p>
            }
            {this.state.period &&
            <p>Godziny wynajmu: <strong>{moment(date).format('HH:mm')} - {this.state.period}</strong></p>
            }
            <p>Dostępna ilość darmowych godzin dla tej sali: <strong>{availableRoomHours}</strong></p>
            <Styled.TextareaWrap>
              <MDBInput
                type="textarea"
                rows="8"
                label="Uwagi do rezerwacji"
                group
                validate
                value={notes}
                onChange={(e) => this.setState({notes: e.currentTarget.value})}
              />
            </Styled.TextareaWrap>

          </MDBModalBody>
          <MDBModalFooter>
            <LHButtonOutline onClick={() => hideModal()}>Zamknij</LHButtonOutline>
            {length > 0 && canUseFreeHours && <LHButton onClick={this.submitForm}>Zarezerwuj</LHButton>}
            {length > 0 && !canUseFreeHours && <LHButton onClick={this.submitForm}>Przejdź do płatności</LHButton>}
          </MDBModalFooter>
          {this.state.submitting &&
          <SubmitSpinner/>
          }
        </MDBModal>
      </Styled.ModalWrap>
    );
  }
}

RoomBookingModalComponent.propTypes = {
  booking: PropTypes.object,
  roomData: PropTypes.object,
  hideModal: PropTypes.func,
};

const mapStateToProps = (state) => ({
  booking: state.rooms.booking,
  roomData: selectRoomById(state, state.rooms.booking.id),
  user: state.user,
  personalStats: state.calendar.personalStats,
});

const mapDispatchToProps = dispatch => {
  return {
    createRoomBooking: (id, bookingStart, bookingLength, notes) => dispatch(createRoomBooking(id, bookingStart, bookingLength, notes)),
    hideModal: () => dispatch({type: ROOM_ACTIONS.HIDE_ROOM_MODAL}),
    getPersonalStats: () => dispatch(getPersonalStats()),
  }
};

export const RoomBookingModal = connect(mapStateToProps, mapDispatchToProps)(RoomBookingModalComponent);
