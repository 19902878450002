import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SideNavigation from './lib/Common/SideNavigation';
import './style.css';
import {connect} from "react-redux";
import roomsData from "./mock/roomsData";
import {ROOM_ACTIONS} from "./lib/Rooms/Rooms.actions";
import { Route, Switch} from "react-router-dom";
import {fetchUser} from "./lib/User/User.actions";
import {CalendarContainer} from "./lib/Calendar/Calendar.container";
import {DesksContainer} from "./lib/Desks/Desks.container";
import {RoomsContainer} from "./lib/Rooms/Rooms.container";
import {VirtualOfficeContainer} from "./lib/VirtualOffice/VirtualOffice.container";
import {ContactFormContainer} from "./lib/ContactForm/ContactForm.container";
import {DoorsContainer} from "./lib/Doors/Doors.container";
import {DoorsTabletContainer} from "./lib/DoorsTabletContainer/Doors.container";
import {ScreensaverTabletContainer} from "./lib/ScreensaverTabletContainer/Screensaver.container";
import {LoginContainer} from "./lib/User/Login.container";
import {RegistrationContainer} from "./lib/User/Registration.container";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "./theme/globalStyles";
import { lightTheme } from "./theme/theme";
import {RoomContainer} from "./lib/Rooms/Room.container";
import {ChangePasswordContainer} from "./lib/User/ChangePassword.container";
import styled from 'styled-components';
import {Redirect} from "react-router-dom";
import { WallTabletContainer } from "./lib/WallTabletContainer/Doors.container";


const StyledMain = styled.main`
  margin-left: ${props => props.isCollapsed ? '110px' : '270px'};
  transition: margin .2s;
  @media(max-width: 800px) {
    margin-left: 0;
    margin-top: 60px; 
    padding: 10px !important;
  }
`

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isJWTChecked: false,
      isCollapsed: false,
    }
  }
  checkWindowWidth = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 1250) {
      this.setState({
        isCollapsed: true,
      })
    } else {
      this.setState({
        isCollapsed: false,
      })
    }
  }

  async componentDidMount() {
    await this.props.fetchUser();
    this.setState({ isJWTChecked: true });
    this.checkWindowWidth();
    window.addEventListener('resize', this.checkWindowWidth);
  }

  isLogged() {
    const {user} = this.props;
    return user.isLogged;
  }

  toggleMenu = () => {
    this.setState({
      isCollapsed: !this.state.isCollapsed
    })
  };
  toggleMobileMenu = () => {
    if (window.innerWidth < 800) {
      this.setState({
        isCollapsed: !this.state.isCollapsed
      })
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.isLogged()) {
      const {updateRoomsData} = this.props;
      updateRoomsData(roomsData);
    }
  }

  render() {
    if(!this.state.isJWTChecked){
      return null;
    }

    const { user } = this.props;

    return (
      <ThemeProvider theme={ lightTheme }>
        <GlobalStyle />
        <div className="flexible-content">
          {this.isLogged() && (
            <SideNavigation
              isCollapsed={ this.state.isCollapsed }
              toggle={ this.toggleMenu }
              toggleMobile={ this.toggleMobileMenu }
              user={user.email}
            />
            )}
          {this.isLogged() && (
            <StyledMain isCollapsed={this.state.isCollapsed} id="content" className="p-4">
              <>
                <Switch>
                  <Route path='/' exact component={CalendarContainer}/>
                  <Route path='/desks' component={DesksContainer}/>
                  <Route path='/rooms' exact component={RoomsContainer}/>
                  <Route path='/rooms/:id' component={RoomContainer}/>
                  <Route path='/office' component={VirtualOfficeContainer}/>
                  <Route path='/contact' component={ContactFormContainer}/>
                  <Route path='/message' exact component={CalendarContainer}/>
                  <Route path='/doors' component={DoorsContainer}/>
                </Switch>
              </>
            </StyledMain>
          )}
          {!this.isLogged() && (
            <>
              <Switch>
                <Route path='/login' exact component={LoginContainer}/>
                <Route path='/register' exact component={RegistrationContainer}/>
                <Route path='/change-password/:code?' exact component={ChangePasswordContainer}/>
                <Route path='/doors/bdo8bjwyeyysiwhs8g4gc8vy8jm4t4/' exact component={DoorsTabletContainer}/>
                <Route path='/doors/bdo8bjwyeyysiwhs8g4gc8vy8jm4t7/' exact component={WallTabletContainer}/>
                <Route path='/screensaver/' exact component={ScreensaverTabletContainer}/>
                <Route path='/'><Redirect to="/login" /></Route>
              </Switch>
            </>
          )}
        </div>
      </ThemeProvider>
    );
  }
}

App.propTypes = {
  updateRoomsData: PropTypes.func,
  getUser: PropTypes.func,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  return {
    updateRoomsData: (data) => dispatch({type: ROOM_ACTIONS.UPDATE_ROOMS_DATA, payload: data}),
    fetchUser: () => dispatch(fetchUser()),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
