import styled from "styled-components";

export const RegisterForm = styled.form`
  h1 {
    text-align: center;
    margin-bottom: 100px;
    font-weight: bold;
    font-size: 2.6em;
    @media(max-width: 1000px) {
      font-size: 1.8em;
    @media(max-width: 720px) {
      font-size: 1.4em;
    }
    }
  }
  input {
    border-radius: 50px !important;
    padding: 12px 0 12px 20px !important;
    width: calc(100% - 20px);
    background-color: #F1F2F8 !important;
  }
  label {
    left: 20px;
  }
  button {
    width: 100%;
    margin: 0;
  }
`

export const RegisterFormLogin = styled.div`
 padding: 20px 0;
 margin-top: 40px;
 border-top: 1px solid rgba(0,0,0,0.1); 
 text-align: center;
 opacity: .6;
 a {
  padding: 0 0 0 5px;
  font-weight: 600;
  font-size: .9em;
  overflow: inherit;
  display: inline-block;
  &:hover {
    color: var(--main);
    opacity: 1;  
  }
 }
`
export const FormCheckbox = styled.div`
  margin: 20px 0;
  * {
    font-weight: 400;
    font-size: 16px;
    @media(max-width: 500px) {
      font-size: 14px;
    }
  }
  a {
    color: #000;
    opacity: .6;
    display: inline;
    padding: 0 3px;
  }
  label {
    span {
      display: inline-block;
      height: 22px;
      width: 22px;
      background-color: #fff;
      border: 1px solid rgba(0,0,0,0.3);
      border-radius: 2px;
      margin-right: 10px;
      position: relative;
      z-index: 2;
      top: 5px;
      left: 2px;
      transition: border-color .3s;
      cursor: pointer;
      &:hover {
        border-color: var(--main);
      }
      &::after {
        content: '';
        display: block;
        position: absolute;
        height: 6px;
        width: 12px;
        border-left: 3px solid;
        border-bottom: 3px solid;
        border-color: rgba(0,0,0,0.2);
        top: calc(50% - 1px);
        left: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        transition: border-color .3s;
      }
    }
    input {
      display: inline-block;
      height: 20px;
      width: 20px;
      z-index:2;
      opacity: 0;
      position: absolute;
    }
    input:checked ~ span {
      border-color: var(--main);
      background-color: var(--main);
    }
    input:checked ~ span:after {
      border-color: #fff;
    }
  }
`
