import axios from "axios";
import {requestErrorHandler} from "../../common/helpers/errorHandler";
import { backendHost } from "../../common/helpers/http";


export const openDoor = (type, data) => async (dispatch, getState) => {
    try {
        const state = getState();
        const response = await axios.post(`${backendHost}/auth/openDoors`, {
            type: type,
            data: data
        }, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return {
            open: true,
            message: "Wszystko OK",
            state: 'info',
            submitting: false,
            error: null
        }

    } catch (error) {
        return {
            open: false,
            message: "Błąd",
            state: 'error',
            submitting: false,
            error: error.response.data.message
        }
    }
};
