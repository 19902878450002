import React, {Component} from 'react';
import BreadcrumSection from "../Common/BreadcrumSection";
import {
  AppointmentForm,
  Appointments,
  DateNavigator,
  Scheduler,
  Toolbar, WeekView
} from "@devexpress/dx-react-scheduler-material-ui";
import {ViewState} from "@devexpress/dx-react-scheduler";
import {MDBAlert} from "mdbreact";
import moment from "moment";
import {UnavailableSlotAppointment} from "./components/UnavailableSlotAppointment";
import {RoomBookingModal} from "./components/RoomBookingModal";
import PropTypes from "prop-types";
import * as Styled from "../Rooms/Rooms.style";
import Rooms from './Rooms.list'
import RoomTile from "./components/RoomTile";

export class RoomPage extends Component {

  constructor() {
    super();
    this.updateCurrentView = this.updateCurrentView.bind(this);
  }

  componentDidMount() {
    const {getRoomBookings, setRoomId, setBookingDate} = this.props;
    const { id } = this.props.match.params;
    const defaultStartDate = moment().startOf('isoWeek').format('YYYY-MM-DD');
    setRoomId(parseInt(id, 10));
    setBookingDate(defaultStartDate);
    getRoomBookings(id, defaultStartDate, moment(defaultStartDate).add(7, 'days').format('YYYY-MM-DD'));
  }

  updateCurrentView(date){
    const {getRoomBookings} = this.props;
    const { id } = this.props.match.params;
    const bookingStart = moment(date).startOf('isoWeek').format('YYYY-MM-DD');
    getRoomBookings(id, bookingStart, moment(bookingStart).endOf('isoWeek').format('YYYY-MM-DD'));
  }
  render() {
    const {isModalVisible, showModal, bookings, setBookingDate} = this.props;
    const data = bookings.map(booking => {
      return {
        startDate: booking.bookingStart,
        endDate: moment(booking.bookingEnd).add(30, 'minutes'),
        title: `Sala zajęta`,
      }
    });
    const roomId = this.props.match.params.id;
    const room = Rooms.find(room => room.id === parseInt(roomId));
    return (
      <>
        <BreadcrumSection
          title="Sale szkoleniowe"
          subtitle="Zarezerwuj salę na swoje spotkanie biznesowe"
          icon="square"
        />
        <Styled.RoomBooking>
          <Styled.BackButtonWrap>
            <Styled.BackButton to="/rooms">
              <i className="fas fa-long-arrow-alt-left"></i>
              Zmień salę
            </Styled.BackButton>
          </Styled.BackButtonWrap>
          {room &&
          <Styled.Tile details>
            <RoomTile
              details
              id={room.id}
              price={room.price}
              image={room.image}
              name={room.name}
              description={room.description}
            >
              <Styled.TilePerks details>
                <ul>
                  {room.perks.map((perk, index) => (
                    <Styled.PlanPerk key={index}>
                      {perk.name}
                    </Styled.PlanPerk>
                  ))}
                </ul>
              </Styled.TilePerks>
            </RoomTile>
          </Styled.Tile>
          }


          {room &&
          <>
            <MDBAlert color="primary" >
              Kliknij dwa razy w wybrany wolny termin by zarezerwować salę.
            </MDBAlert>

            <Styled.SchedulerWrap>
              <Scheduler
                locale="pl-PL"
                firstDayOfWeek={1}
                data={data}
              >
                <ViewState
                  defaultCurrentDate={moment().format()}
                  onCurrentDateChange={this.updateCurrentView}
                />
                <WeekView
                  startDayHour={9}
                  endDayHour={18}
                  displayName="Rezerwacja sali"
                />
                <Toolbar />
                <DateNavigator />
                <Appointments
                  appointmentComponent={UnavailableSlotAppointment}
                />
                <AppointmentForm
                  visible={false}
                  onAppointmentDataChange={ (data) => {
                    const dateTime = moment(data.startDate).format('YYYY-MM-DD HH:mm');
                    setBookingDate(dateTime);
                    showModal();
                  }}
                />
              </Scheduler>

              {isModalVisible && <RoomBookingModal/>}
            </Styled.SchedulerWrap>
          </>
          }
          {!room &&
          <div>Sala nie istnieje</div>
          }
        </Styled.RoomBooking>
      </>
    )
  }
}

RoomPage.propTypes = {
  isModalVisible: PropTypes.bool,
  showModal: PropTypes.func,
};
