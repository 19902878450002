import React from 'react';
import { MDBLink} from 'mdbreact';
import {LHButton, LHButtonOutline} from "../../theme/globalStyles";
import * as Styled from './BreadcrumSection.style';
import coworkingIcon from "../../assets/icons/work-station.svg"
import conferenceIcon from "../../assets/icons/hanger.svg"
import officeIcon from "../../assets/icons/location.svg"

const wordFormat = (quantity) => {
  if(quantity > 1) return 'biurek';
  return 'biurka';
}

const CardsSection = ({stats}) => {
  if(!stats) return '';

  return (
    <Styled.CardsWrap>
      <Styled.CardsItem>
        <div>
          <Styled.CardsTitle>
            <Styled.CardsIcon icon={ coworkingIcon } />
            <div>MOJE BIURKA</div>
          </Styled.CardsTitle>
          {stats.desks && <p>Posiadasz rezerwację <strong>{stats.desks}</strong> {wordFormat(stats.desks)}</p>}
          {!stats.desks && <p>Nie posiadasz aktualnych rezerwacji.</p>}
        </div>
        <div><MDBLink to={`/desks`} className="p-0"><LHButton size="sm">Dodaj</LHButton></MDBLink></div>
      </Styled.CardsItem>
      <Styled.CardsItem>
        <div>
          <Styled.CardsTitle>
            <Styled.CardsIcon icon={ conferenceIcon } />
            <div>MOJE SALE</div>
          </Styled.CardsTitle>
          <p>Możesz zarezerwować sale na <strong>{stats.availableRoomsHours} godzin</strong></p>
        </div>
        <div>
          <LHButtonOutline size="sm">Dokup czas</LHButtonOutline>
          <MDBLink to={`/rooms`} className="p-0" style={{display: 'inline'}}><LHButton size="sm">Rezerwuj</LHButton></MDBLink>
        </div>
      </Styled.CardsItem>
      <Styled.CardsItem>
        <div>
          <Styled.CardsTitle>
            <Styled.CardsIcon icon={ officeIcon }/>
            <div>MOJE WIRTUALNE BIURO</div>
          </Styled.CardsTitle>
        {stats.hasVirtualOffice && (
          <>
            <p>Twój pakiet wirtualnego biura jest aktywy.</p>
          </>
        )}
        {!stats.hasVirtualOffice && (
          <>
            <p>Nie posiadasz jeszcze pakietu wirtualnego biura.</p>
          </>
        )}
        </div>
        {stats.hasVirtualOffice && (
          <div>
            <MDBLink to={`/contact`} className="p-0"><LHButton size="sm">Kontakt</LHButton></MDBLink>
          </div>
        )}
        {!stats.hasVirtualOffice && (
          <div>
            <MDBLink to={`/office`} className="p-0"><LHButton size="sm">Zamów</LHButton></MDBLink>
          </div>
        )}
      </Styled.CardsItem>
    </Styled.CardsWrap>
  )
}

export default CardsSection;

