import axios from "axios";
import {backendHost} from "../../common/helpers/http";
import {requestErrorHandler} from "../../common/helpers/errorHandler";

export const ROOM_ACTIONS = {
  UPDATE_ROOMS_DATA: 'UPDATE_ROOMS_DATA',
  UPDATE_ROOM_BOOKINGS: 'UPDATE_ROOM_BOOKINGS',
  SHOW_ROOM_MODAL: 'SHOW_ROOM_MODAL',
  HIDE_ROOM_MODAL: 'HIDE_ROOM_MODAL',
  SET_ROOM_ID: 'SELECT_ROOM',
  SET_BOOKING_DATE: 'SELECT_BOOKING_DATE',
};

export const createRoomBooking = (roomId, bookingStart, bookingLength, notes) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {accessToken} = state.user;
    const response = await axios.post(`${backendHost}/room/booking`, {
      bookingStart,
      bookingLength,
      itemId: roomId,
      itemType: 'ROOM',
      notes: notes,
    }, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });
    const {data} = response;
    return data;
  } catch (error) {
    requestErrorHandler(error);
    throw error.response.data;
  }
};

export const getRoomBookings = (id, bookingStart, bookingEnd) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {accessToken} = state.user;
    const response = await axios.get(
      `${backendHost}/booking/?itemType=ROOM&itemId=${id}&bookingStart=${bookingStart}&bookingEnd=${bookingEnd}`,
      {
        headers: {'Authorization': `Bearer ${accessToken}`}
      });

    const {data} = response;
    dispatch({type: ROOM_ACTIONS.UPDATE_ROOM_BOOKINGS, payload: data});
    return data;
  } catch (error) {
    requestErrorHandler(error);
    return false;
  }
};

export const removeRoomBooking = (id) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {accessToken} = state.user;
    const response = await axios.delete(
      `${backendHost}/room/booking/${id}`,
      {
        headers: {'Authorization': `Bearer ${accessToken}`}
      });
    const {data} = response;
    //dispatch({type: DESK_ACTIONS.REMOVE_BOOKING, payload: {id}});
    return data;
  } catch (error) {
    requestErrorHandler(error);
    return false;
  }
};
