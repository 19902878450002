import React, { Component } from 'react';
import {MDBListGroup, MDBListGroupItem, MDBIcon, MDBLink} from 'mdbreact';
import {NavLink} from 'react-router-dom';
import Logout from "./Logout";
import * as Styled from './SideNavigation.style';


class SideNavigation extends Component {

  render() {
    return (
      <Styled.NavWrap collapsed={this.props.isCollapsed}>
        <div className="sidebar-fixed position-fixed">
          <div>
            <Styled.MobileToggle onClick={this.props.toggle}/>
            <MDBLink to="/" className="logo-wrapper waves-effect" onClick={this.props.toggleMobile}>
              <Styled.NavLogo/>
            </MDBLink>
            <MDBListGroup className="list-group-flush">
              <NavLink exact={true} to="/" activeClassName="active" onClick={this.props.toggleMobile}>
                <MDBListGroupItem>
                  <MDBIcon far icon="calendar" className="mr-3"/>
                  <span>Pulpit</span>
                </MDBListGroupItem>
              </NavLink>
              <NavLink to="/desks" activeClassName="active" onClick={this.props.toggleMobile}>
                <MDBListGroupItem>
                  <MDBIcon far icon="lemon" className="mr-3"/>
                  <span>Rezerwacja biurka</span>
                </MDBListGroupItem>
              </NavLink>
              <NavLink to="/rooms" activeClassName="active" onClick={this.props.toggleMobile}>
                <MDBListGroupItem>
                  <MDBIcon far icon="square" className="mr-3"/>
                  <span>Rezerwacja sali</span>
                </MDBListGroupItem>
              </NavLink>
              <NavLink to="/office" activeClassName="active" onClick={this.props.toggleMobile}>
                <MDBListGroupItem>
                  <MDBIcon far icon="building" className="mr-3"/>
                  <span>Wirtualne biuro</span>
                </MDBListGroupItem>
              </NavLink>
              <NavLink to="/contact" activeClassName="active" onClick={this.props.toggleMobile}>
                <MDBListGroupItem>
                  <MDBIcon far icon="paper-plane" className="mr-3"/>
                  <span>Kontakt</span>
                </MDBListGroupItem>
              </NavLink>
              <NavLink to="/doors" activeClassName="active" onClick={this.props.toggleMobile}>
                <MDBListGroupItem>
                  <MDBIcon far icon="building" className="mr-3"/>
                  <span>Wejście</span>
                </MDBListGroupItem>
              </NavLink>
            </MDBListGroup>
          </div>
          <Styled.NavFooter>
            <Styled.NavToggle onClick={this.props.toggle}/>
            <Logout collapsed={this.props.isCollapsed} user={this.props.user}/>
          </Styled.NavFooter>
        </div>
      </Styled.NavWrap>
    );
  }
}

export default SideNavigation;
