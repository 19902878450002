import styled from "styled-components";
import {Link} from "react-router-dom";

export const Tiles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 25px;
  flex: 1;
`
export const Tile = styled.div`
  background-color: ${({ theme }) => theme.background};
  border-radius: 4px;
  width: 100%;
  padding: ${props => props.details ? '0' : '20px'};
  border: 2px solid ${ props => props.promoted ? "var(--main)" : "transparent"};
  display: flex;
  justify-content: space-between;
  margin: 0 0 15px;
`
export const PlanPerk = styled.li`
  position: relative;
  list-style: none;
  padding: 8px 10px 8px 10px;
  opacity: ${ props => props.unactive ? '.4' : '1' };
  width: 50%;
  &:before {
    content: "";
    display: inline-block;
    height: 8px;
    width: 20px;
    border-left: 3px solid var(--main);
    border-bottom: 3px solid var(--main);
    position: absolute;
    left: -20px;
    top: 13px;
    transform: rotate(-45deg);
  }
  &:after {
    content: "";
    display: inline-block;
    height: 20px;
    width: 20px;
    background-color: var(--main);
    opacity: .25;
    border-radius: 50px;
    position: absolute;
    left: -22px;
    top: 11px;
  }
`
export const TilePerks = styled.div`
  padding: 20px 0 0;
  margin: 25px 20px 0;
  border-top: ${props => props.details ? 'none' : '2px dashed rgba(0,0,0,.1)'};
  ul {
    padding-left: 20px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
  }
`

export const RoomImage = styled.div`
  background-image: linear-gradient(to top, rgba(33, 27, 86, 0.6), transparent), url(${ props => props.bg});
  background-size: cover;
  background-position: center center;
  border-radius: 4px;
  height: 250px;
  width: 370px;
  position: relative;
`
export const PlanPrice = styled.div`
  position: absolute;
  bottom: 20px;
  left: 10px;
  strong {
    background-color: var(--main);
    padding: 10px 20px;
    border-radius: 40px;
    color: #fff;
  }
`
export const TileTitle = styled.div`
  padding: 10px 0 15px;
  margin-left: 20px;
  display: flex;
  justify-content: space-between;
`

export const BackButtonWrap = styled.div`
  margin-bottom: 20px; 
  padding-bottom: 20px;
  border-bottom: 2px dashed rgba(0,0,0,0.1);
`
export const BackButton = styled(Link)`
  cursor: pointer;
  opacity: .6;
  padding: 20px;
  i {
    margin-right: 10px;
  }
`

export const RoomDetails = styled.div`
  flex:1;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
`
export const RoomHeader = styled.div`
`
export const RoomBooking = styled.div`
  background-color: #fff;
  padding: 40px;
  a {
    padding-left: 0;
  }
`
export const SchedulerWrap = styled.div`
  background-color: #fff;
  border: 1px solid #eee;
  margin-top: 20px;
  div[class*="VerticalAppointment-title"] {
    color: #9c9ea2;
  }
  div[class*="VerticalAppointment-textContainer"] {
    color: #9c9ea2;
  }
  div[class*="Cell-today"] {
    background-color: var(--main);
  }
`
export const RoomsWrap = styled.div`
  display: flex;
`
export const MyReservationsButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
`
export const MyReservations = styled.div`
  background-color: #fff;
  border-radius: 4px;
  margin-left: 15px;
  min-width: 300px;
  max-width: 300px;
  padding: 20px;
  margin-bottom: 40px;
  h6 {
    font-weight: bold;
  }
  div[class*="reservation-tile"] {
    margin: 10px 0;
    padding: 8px;
    border-radius: 4px;
    background-color: #e1defb;
  }
  div[class*="past"] {
    background-color: #e7e7e8;
  }
`
