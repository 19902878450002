
const DesksPlans = [
  {
    id: 1,
    plan: 'day',
    promoted: false,
    name: 'Plan Dzienny',
    description: 'Biurko hot-desk na jeden lub kilka dni',
    price: '38 zł/dzień',
    perks: [
      {name: 'Destęp do wolnego biurka', type:'normal'},
      {name: 'Dostęp do sprzetu biurowego', type:'normal'},
      {name: 'Darmowa kawa/herbata/woda', type:'normal'},
      {name: 'Bez ograniczenia czasowego', type: 'normal'},
      {name: 'Dedykowane biurko', type:'unavailable'},
      {name: 'Dedykowana szafka', type:'unavailable'},
      {name: 'Dostęp do sali konferencyjnej', type:'unavailable'}
    ],
    hoursRatio: 1,
  },
  {
    id: 2,
    plan: 'week',
    promoted: false,
    name: 'Plan 15 dni',
    description: 'Biurko hot-desk bądź biurko dedykowane',
    price: '340 zł/tydzień',
    perks: [
      {name: 'Destęp do wolnego biurka', type:'normal'},
      {name: 'Dostęp do sprzetu biurowego', type:'normal'},
      {name: 'Darmowa kawa/herbata/woda', type:'normal'},
      {name: 'Dostęp do sali konferencyjnej', type:'normal'},
      {name: 'Bez ograniczenia czasowego', type: 'normal'},
      {name: 'Dedykowane biurko', type:'unavailable'},
      {name: 'Dedykowana szafka', type:'unavailable'}
    ],
    hoursRatio: 1,
  },
  {
    id: 3,
    plan: 'year',
    promoted: true,
    name: 'Plan Roczny',
    description: 'Dedykowane biurko na rok',
    price: '699 zł/miesiąc',
    perks: [
      {name: 'Dedykowane biurko', type:'strong'},
      {name: 'Dedykowana szafka', type:'strong'},
      {name: 'Dostęp do sali konferencyjnej(5h)', type:'strong'},
      {name: 'Dostęp do sprzetu biurowego', type:'normal'},
      {name: 'Darmowa kawa/herbata/woda', type:'normal'},
      {name: 'Bez ograniczenia czasowego', type: 'normal'},
    ],
    hoursRatio: 2,
  },
  {
    id: 4,
    plan: 'month',
    promoted: false,
    name: 'Plan Miesięczny',
    description: 'Dedykowane biurko na miesiąc lub dłużej',
    price: '778 zł/miesiąc',
    perks: [
      {name: 'Dedykowane biurko', type:'strong'},
      {name: 'Dedykowana szafka', type:'strong'},
      {name: 'Dostęp do sali konferencyjnej(5h)', type:'strong'},
      {name: 'Dostęp do sprzetu biurowego', type:'normal'},
      {name: 'Darmowa kawa/herbata/woda', type:'normal'},
      {name: 'Bez ograniczenia czasowego', type: 'normal'},
    ],
    hoursRatio: 2,
  }
]

export default DesksPlans;
