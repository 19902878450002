import React, {Component} from 'react';
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBAlert} from 'mdbreact';
import {connect} from "react-redux";
import {createBooking, DESK_ACTIONS} from "../Desks.actions";
import PropTypes from "prop-types";
import moment from "moment";
import * as momentBusinessDays from 'moment-business-days';
import {stripePublicKey} from "../../../common/helpers/stripe";
import * as Styled from "./DayWeekDeskBookingModal.style";
import {LHButton, LHButtonOutline, LHInputWrap} from "../../../theme/globalStyles";
import SubmitSpinner from "../../Common/SubmitSpinner";

class DailyDeskBookingModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookingStart: this.props.startDate,
      bookingLength: null,
      bookingLengthValidation: false,
      quantity: 1,
      hasUnavailableDay: false,
      submitting: false,
    };

    this.submitForm = this.submitForm.bind(this);
  }

  async submitForm(){
    const {bookingStart, bookingLength, quantity} = this.state;
    if (!bookingLength) {
      return this.setState({bookingLengthValidation: true});
    }
    this.setState({
      submitting: true,
    });

    try {
      const response = await this.props.createBooking(bookingStart, bookingLength, quantity);
      this.redirectToPayment(response.stripeSessionId);
    }catch (e) {
      this.setState({
        submitting: false,
      });
      if (e.message === 'HAS_UNAVAILABLE_DAY') {
        this.setState({hasUnavailableDay: true});
      }
    }
  }

  redirectToPayment(sessionId) {
    const stripe = window.Stripe(stripePublicKey);
    stripe.redirectToCheckout({
      sessionId,
    }).then(function (result) {
      console.error(result);
    });
  }

  render() {
    const {hideModal, startDate} = this.props;
    const {bookingStart, bookingLength, hasUnavailableDay} = this.state;
    const period = bookingLength ? `${moment(bookingStart).format('DD.MM.YYYY')} - 
    ${momentBusinessDays(bookingStart).businessAdd(bookingLength - 1).format('DD.MM.YYYY')}` : null;

    return (
      <Styled.ModalWrap>
        <MDBModal isOpen={true} toggle={() => hideModal()} size="lg">
          <MDBModalHeader toggle={() => hideModal()}>
            Rezerwacja biurka
          </MDBModalHeader>
          <MDBModalBody>
            <div style={{marginBottom: '20px'}}>
              {hasUnavailableDay &&
              <MDBAlert color="danger" >
                Wybrany okres rezerwacji {period} zawiera dzień bez dostępnych biurek.
              </MDBAlert>
              }
              <Styled.ModalForm>
                <div>
                  <Styled.FormLabel>
                    <span>1</span> Liczba biurek
                  </Styled.FormLabel>
                  <LHInputWrap>
                    <select className="browser-default custom-select"
                            onChange={(e) =>
                              this.setState({quantity: parseInt(e.currentTarget.value)})
                            }
                    >
                      <option value="1">1 biurko</option>
                      <option value="2">2 biurka</option>
                      <option value="3">3 biurka</option>
                      <option value="4">4 biurka</option>
                      <option value="5">5 biurek</option>
                    </select>
                  </LHInputWrap>
                </div>
                { this.props.plan === 'day' &&
                <div>
                  <Styled.FormLabel>
                    <span>2</span> Ilość dni
                  </Styled.FormLabel>
                  <LHInputWrap validation={ this.state.bookingLengthValidation }>
                    <select className="browser-default custom-select"
                            onChange={(e) =>
                              this.setState({
                                bookingLength: parseInt(e.currentTarget.value),
                                bookingLengthValidation: false
                              })
                            }
                    >
                      <option>Wybierz</option>
                      <option value="1">1 dzień</option>
                      <option value="2">2 dni</option>
                      <option value="3">3 dni</option>
                      <option value="5">4 dni</option>
                    </select>
                  </LHInputWrap>
                </div>
                }
                { this.props.plan === 'week' &&
                <div>
                  <Styled.FormLabel>
                    <span>2</span> Ilość tygodni
                  </Styled.FormLabel>
                  <LHInputWrap validation={ this.state.bookingLengthValidation }>
                    <select className="browser-default custom-select"
                            onChange={(e) =>
                              this.setState({
                                bookingLength: parseInt(e.currentTarget.value),
                                bookingLengthValidation: false
                              })
                            }
                    >
                      <option>Wybierz</option>
                      <option value="5">1 tydzień</option>
                      <option value="10">2 tygodnie</option>
                      <option value="15">3 tygodnie</option>
                    </select>
                  </LHInputWrap>
                </div>
                }

              </Styled.ModalForm>
            </div>

            {!period &&
            <p>
              Początek rezerwacji: <strong>{startDate}</strong>
            </p>
            }
            {period &&
            <p>
              Okres rezerwacji: <strong>{period}</strong>
              <Styled.ModalInfo>(Wybrana rezerwacja nie uwzględnia dni weekendowych)</Styled.ModalInfo>
            </p>
            }

          </MDBModalBody>
          <MDBModalFooter>
            <LHButtonOutline color="default" onClick={() => hideModal()}>Zamknij</LHButtonOutline>
            <LHButton onClick={this.submitForm}>Przejdź do płatności</LHButton>
          </MDBModalFooter>
          { this.state.submitting &&
            <SubmitSpinner />
          }
        </MDBModal>
      </Styled.ModalWrap>
    );
  }
}

DailyDeskBookingModalComponent.propTypes = {
  hideModal: PropTypes.func,
  startDate: PropTypes.string,
  createBooking: PropTypes.func,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  startDate: state.desks.booking.startDate,
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  return {
    hideModal: () => dispatch({type: DESK_ACTIONS.HIDE_DAILY_DESK_BOOKING_MODAL}),
    createBooking: (bookingStart, bookingLength, quantity) =>
      dispatch(createBooking(bookingStart, bookingLength, quantity)),
  }
};

export const DayWeekDeskBookingModal = connect(mapStateToProps, mapDispatchToProps)(DailyDeskBookingModalComponent);
