import styled from "styled-components";

export const Tiles = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`
export const Tile = styled.div`
  background-color: ${({ theme }) => theme.background};
  border-radius: 4px;
  width: 100%;
  padding: 20px;
  border: 2px solid ${ props => props.promoted ? "var(--main)" : "transparent"};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:nth-child(2) { 
    margin-left: 20px;
  }
`

export const PlanPerk = styled.li`
  position: relative;
  list-style: none;
  padding: 8px 0 8px 10px;
  opacity: ${ props => props.unactive ? '.4' : '1' };
  &:before {
    content: "";
    display: inline-block;
    height: 8px;
    width: 20px;
    border-left: 3px solid var(--main);
    border-bottom: 3px solid var(--main);
    position: absolute;
    left: -20px;
    top: 13px;
    transform: rotate(-45deg);
  }
  &:after {
    content: "";
    display: inline-block;
    height: 20px;
    width: 20px;
    background-color: var(--main);
    opacity: .25;
    border-radius: 50px;
    position: absolute;
    left: -22px;
    top: 11px;
  }
`
export const TilePerks = styled.div`
  padding: 20px 0;
  margin: 20px 20px 0;
  border-top: 2px dashed rgba(0,0,0,.1);
  ul {
    padding-left: 20px;
  }
`
export const PlanPrice = styled.div`
  margin-top: 10px;
`
export const TileTitle = styled.div`
  padding: 20px;
`
export const TileAlert = styled.div`
  background-color: #f2f1ff;
  padding: 20px;
  margin: 20px 0 40px;
  border-radius: 4px;
`
export const FormWrap = styled.div`
  margin: 20px 20px 0;
  padding: 20px 0;
  border-top: 2px dashed rgba(0,0,0,.1);
`
export const Form = styled.form`
  input, textarea {
    padding: 12px 0 12px 20px !important;
    width: calc(100% - 20px);
    background-color: #F1F2F8 !important;
    height: auto !important;
    border: none; 
    border-bottom: 1px solid #ced4da !important;
  }
  input {
    border-radius: 50px !important;
  }
  textarea {
    border-radius: 12px !important;
 } 
  label {
    left: 20px;
  }

`
export const FormTiles = styled.div`
  display: flex;
  margin: 40px 0 !important;
  flex-wrap: wrap;
`
export const FormTile = styled.div`
  flex: 1;
  position: relative;
  &:nth-of-type(2){
    margin: 0 20px;
  }
  input {
    height: 100% !important;
  }
  div {
    border: 2px solid #d9ddf5;
    background-color: #F1F2F8 !important;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: bold;
    width: 100%;
  }
  span {
    font-weight: 300;
  }
  img {
    width: 64px;
    height: 64px;
    margin-bottom: 20px;
  }
`
export const FormRadio = styled.input `
  position: absolute;
  top: 0;
  left: 0;
  height: 100% !important;
  width: 100% !important;
  opacity: 0;
  cursor: pointer;
  &:checked + div {
    color: var(--main);
    border-color: var(--main);
  }
`
export const FormRadioText = styled.div`
  border: 1px solid rgba(0,0,0,0.15);
  background-color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  color: rgba(0,0,0,0.6);
  font-weight: bold;
  margin: 0 !important;
`
export const FormRadioPrice = styled.span`
  font-size: 1.4em !important;
  color: var(--main);
  font-weight: 700 !important;
`
export const FormLabel = styled.div`
  font-weight: bold;
  margin-bottom: 20px;
  span {
    display: inline-block;
    height: 26px;
    width: 26px;
    margin-right: 5px;
    background-color: #E6E3FC;
    color: var(--main);
    font-size: .9em;
    border-radius: 50px;
    text-align: center;
    line-height: 26px;
  }
`
