import {connect} from "react-redux";
import DoorsPage from "./Doors.page";
import {openDoor} from "../DoorsTabletContainer/Doors.actions";

const mapStateToProps = (state) => ({
  user: state.user.id,
});

const mapDispatchToProps = dispatch => {
  return {
    openDoor: (type, data) => dispatch(openDoor(type, data)),
  }
};

export const WallTabletContainer = connect(mapStateToProps, mapDispatchToProps)(DoorsPage);
