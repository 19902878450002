import React, { Component } from "react";
import styled, { keyframes } from 'styled-components'

const spin = keyframes`
  from {
    transform: rotate(0);
  }
  to{
    transform: rotate(359deg);
  }
`

const StyledSpinner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: radial-gradient(#fff, rgba(255,255,255, .7));
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 .circle-border {
  width: 150px;
  height: 150px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgb(63,249,220);
  background: linear-gradient(0deg, rgba(135, 123, 237, .1) 33%, rgba(135, 123, 237, 1) 100%);
  animation: ${spin} .8s linear 0s infinite;
} 
.circle-core {
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,.9);
  border-radius: 50%;
}
`
const StyledSpinnerMessage = styled.div`
  text-align: center;
  margin-top: 20px;
`
class SubmitSpinner extends Component {

  render() {
    return (
      <StyledSpinner>
        <div className="circle-border">
          <div className="circle-core" />
        </div>
        <StyledSpinnerMessage>Wysyłanie</StyledSpinnerMessage>
      </StyledSpinner>
    )
  }
}

export default SubmitSpinner;
