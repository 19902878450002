import styled from "styled-components"

export const CardsIcon = styled.div`
  display: inline-block;
  margin-right: 20px;
  height: 200px;
  width: 200px;
  position: absolute;
  opacity: .2;
  top: 30px;
  right: -60px;
  background-image: url(${props => props.icon });
  background-size: contain;
  @media(max-width: 1450px) {
    opacity: .1;
  }
`

export const CardsWrap = styled.div`
  display: flex;
  margin: 30px 0;
  @media(max-width: 900px) {
    flex-direction: column; 
    text-align: center;
  }
`
export const CardsItem = styled.div`
  flex: 1;
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:nth-child(2) {
    margin: 0 30px;
    @media(max-width: 1450px){
      margin: 0 10px;
    }
    @media(max-width: 900px){
      margin: 10px 0;
    }
  }
  p {
    opacity: .6;
    @media(max-width: 1100px) {
      font-size: 14px;
    }
  }
  button {
    margin-left: 0;
  }
`
export const CardsTitle = styled.div`
  font-weight: bold;
  div {
    margin-bottom: 10px;
  }
`

