import React, {Component} from 'react';
import CardsSection from '../Common/CardsSection';
import {
  Scheduler,
  MonthView,
  Appointments,
  AppointmentTooltip,
  Toolbar,
  DateNavigator,
  TodayButton,
  Resources,
} from '@devexpress/dx-react-scheduler-material-ui';
import BreadcrumSection from "../Common/BreadcrumSection";
import {ViewState} from "@devexpress/dx-react-scheduler";
import moment from "moment";
import * as qs from 'query-string';
import {MDBAlert} from "mdbreact";
import * as Styled from "./Calendar.style";


export class CalendarPage extends Component {

  componentDidMount() {
    const {getPersonalBookings, getPersonalStats} = this.props;
    getPersonalBookings(moment().format('YYYY'), moment().format('MM'));
    getPersonalStats();
  }

  formatWord(itemType, quantity) {
    if (itemType === 'DESK') {
      if (quantity === 1) return 'biurko';
      let lastDigit;
      if (String(quantity).length > 1) {
        lastDigit = parseInt(String(quantity)[String(quantity).length - 1]);
      } else {
        lastDigit = quantity;
      }
      if (lastDigit === 1 || lastDigit > 4) return 'biurek';
      return 'biurka';
    }
  }

  formatBookingTitle(booking) {
    if (booking.itemType === 'ROOM') {
      return `Sala ${booking.itemId}: 
      ${moment(booking.bookingStart).format('HH:mm')} - ${moment(booking.bookingEnd).format('HH:mm')}`;
    } else {
      if (booking.itemId) {
        return `Biurko dedykowane nr ${booking.itemId}`;
      } else {
        return `Hot-desk: ${booking.quantity} ${this.formatWord('DESK', booking.quantity)} 
        ${moment(booking.bookingStart).format('DD.MM.YYYY')} - ${moment(booking.bookingEnd).format('DD.MM.YYYY')}`;
      }
    }
  }

  getColorId(data) {
    if (data.itemType === 'ROOM')
      return 1;
    else if (data.itemId)
      return 2;
    else
      return 3;
  }

  formatCalendarData(data) {
    return data.map((booking) => {
      return {
        startDate: moment(booking.bookingStart).format('YYYY-MM-DD HH:mm'),
        endDate: moment(booking.bookingEnd).format('YYYY-MM-DD HH:mm'),
        title: this.formatBookingTitle(booking),
        colorId: this.getColorId(booking),
        notes: booking.notes
      }
    });
  }

  dataTable() {
    const personalBookings = this.props.personalBookings;
    const hotDesks = personalBookings.filter(booking => {
      return booking.itemType === 'DESK' && booking.itemId === null
    })
    const dedicated = personalBookings.filter(booking => {
      return booking.itemType === 'DESK' && booking.itemId !== null
    })
    const room1 = personalBookings.filter(booking => {
      return booking.itemType === 'ROOM' && booking.itemId === 1
    })
    const room2 = personalBookings.filter(booking => {
      return booking.itemType === 'ROOM' && booking.itemId === 2
    })
    return {
      desks: {
        hotDesks: hotDesks,
        dedicated: dedicated,
      },
      rooms: {
        room1: room1,
        room2: room2,
      }
    }
  }


  appointmentNotes({children, appointmentData, ...restProps}) {
    return (
      <AppointmentTooltip.Content {...restProps} appointmentData={appointmentData}>
        {appointmentData.notes &&
        <Styled.AppointmentNotes>
          <Styled.AppointmentNotesLabel>Uwagi:</Styled.AppointmentNotesLabel>
          <Styled.AppointmentMessage>{appointmentData.notes}</Styled.AppointmentMessage>
        </Styled.AppointmentNotes>
        }
      </AppointmentTooltip.Content>
    )
  }


  render() {
    const {personalBookings, personalStats} = this.props;
    const data = this.formatCalendarData(personalBookings);
    const queryParams = qs.parse(window.location.search);
    console.log(data)

    return (
      <>
        <BreadcrumSection
          title="Pulpit"
          subtitle="Podsumowanie Twoich rezerwacji"
          icon="calendar"
        />
        <CardsSection stats={personalStats}/>

        {queryParams && queryParams.payment && (
          <MDBAlert color="success" >Dziękujemy za dokonanie płatności.</MDBAlert>
        )}

        {queryParams && queryParams.roomBooking && (
          <MDBAlert color="success" >Sala została zarezerwowana.</MDBAlert>
        )}

        <div style={{background: '#FFF'}}>
          <Scheduler
            locale="pl-PL"
            firstDayOfWeek={1}
            data={data}
            appointmentTooltipRender={this.renderAppointmentTooltip}
          >
            <ViewState
              defaultCurrentDate={moment()}
            />
            <MonthView/>
            <Toolbar/>
            <DateNavigator/>
            <TodayButton/>
            <Appointments/>
            <AppointmentTooltip
              contentComponent={this.appointmentNotes}
            />
            <Resources
              data={[{
                id: 1,
                fieldName: 'colorId',
                title: 'Rezerwacja',
                instances: [
                  {id: 1, color: '#beb9ed'},
                  {id: 2, color: '#877BED'},
                  {id: 3, color: '#a79fed'},
                ],
              }]}
            />
          </Scheduler>
        </div>
      </>
    );
  }
}
