export const lightTheme = {
  body: '#eef0f5',
  text: '#222731',
  background: `#fff`,
  toggleBorder: '#FFF',
  borderColor: '#E4E5E9',
  gradient: 'linear-gradient(#39598A, #79D7ED)',
}

export const darkTheme = {
  body: '#282E5A',
  text: '#fff',
  background: `#343B6F`,
  toggleBorder: '#FFF',
  borderColor: '#334073',
  gradient: 'linear-gradient(#39598A, #79D7ED)',
}
