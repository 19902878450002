import {logoutCurrentUser} from "../../lib/User/User.actions";

export const requestErrorHandler = (error) => {
  if(error && error.response) {
    if (error.response.status === 401) {
      logoutCurrentUser();
    }else{
      console.error(error);
    }
  }
};