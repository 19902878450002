import { combineReducers } from "redux";
import { DesksReducer } from "../lib/Desks/Desks.reducer";
import { RoomsReducer } from "../lib/Rooms/Rooms.reducer";
import { UserReducer } from "../lib/User/User.reducer";
import {CalendarReducer} from "../lib/Calendar/Calendar.reducer";

export default combineReducers({
  desks: DesksReducer,
  rooms: RoomsReducer,
  user: UserReducer,
  calendar: CalendarReducer,
});
