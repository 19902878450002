import {connect} from "react-redux";
import ContactFormPage from "./ContactForm.page";
import {submitMessage} from "./ContactForm.actions";

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = dispatch => {
  return {
    submitMessage: (subject, message) => dispatch(submitMessage(subject, message)),
  }
};

export const ContactFormContainer = connect(mapStateToProps, mapDispatchToProps)(ContactFormPage);
