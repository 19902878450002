export default [
  {
    id: 1,
    name: 'Sala wideokonferencyjna - mała',
    photo: 'https://mdbootstrap.com/img/Photos/Others/images/43.jpg',
    description: 'Opis sali pierwszej'
  },
  {
    id: 2,
    name: 'Sala wideokonferencyjna - duża',
    photo: 'https://mdbootstrap.com/img/Photos/Others/images/43.jpg',
    description: 'Opis sali drugiej'
  },
  {
    id: 3,
    name: 'Sala szkoleniowa',
    photo: 'https://mdbootstrap.com/img/Photos/Others/images/43.jpg',
    description: 'Opis sali drugiej'
  },
]
