import smallRoom from '../../assets/room.jpg'
import smallRoom2 from '../../assets/room3.jpg'
import bigRoom from '../../assets/room2.jpg'
import akwarium from '../../assets/akwarium.jpg'
import szkoleniowa from '../../assets/szkoleniowa.JPG'

const Rooms = [
  // {
  //   id: 1,
  //   name: 'Sala wideokonferencyjna - mała',
  //   description: 'Pokój idealny do spotkań biznesowych i wideokonferencji',
  //   price: '40',
  //   image: `${smallRoom2}`,
  //   perks: [
  //     {name: '10 miejsc'},
  //     {name: 'Telewizor'},
  //     {name: 'Dostęp do Wi-Fi'},
  //     {name: 'Kamera do wideokonferencji'},
  //     {name: 'Nagłośnienie'},
  //   ],
  //   hoursRatio: 1,
  // },
  // {
  //   id: 1,
  //   name: 'Sala wideokonferencyjna - akwarium',
  //   description: 'Pokój idealny do spotkań biznesowych i wideokonferencji',
  //   price: '69',
  //   image: `${akwarium}`,
  //   perks: [
  //     {name: '12 miejsc'},
  //     {name: 'Telewizor'},
  //     {name: 'Dostęp do Wi-Fi'},
  //     {name: 'Kamera do wideokonferencji'},
  //     {name: 'Tablice suchościeralne'},
  //     {name: 'Nagłośnienie'}
  //   ],
  //   hoursRatio: 1,
  // },
  {
    id: 2,
    name: 'Sala wideokonferencyjna - duża',
    description: 'Pokój idealny do spotkań biznesowych i wideokonferencji',
    price: '50',
    image: `${smallRoom}`,
    perks: [
      {name: '15 miejsc'},
      {name: 'Telewizor'},
      {name: 'Dostęp do Wi-Fi'},
      {name: 'Kamera do wideokonferencji'},
      {name: 'Tablice suchościeralne'},
      {name: 'Nagłośnienie'}
    ],
    hoursRatio: 1,
  },
  {
    id: 3,
    name: 'Sala szkoleniowa',
    description: 'Większa sala przeznaczona do organizowania szkoleń i meetupów',
    price: '100',
    image: `${szkoleniowa}`,
    perks: [
      {name: '50 miejsc'},
      {name: 'Rzutnik multimedialny'},
      {name: 'Dostęp do Wi-Fi'},
      {name: 'Wyjście na patio'},
      {name: 'Tablice typu flipchart'},
      {name: 'Catering kawowy'},
      {name: 'Możliwość wykorzystania 12 biurek dostępnych w sali'}
    ],
    hoursRatio: 2,
  }
]

export default Rooms;
