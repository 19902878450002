import {connect} from "react-redux";
import {CalendarPage} from "./Calendar.page";
import {getPersonalBookings, getPersonalStats} from "./Calendar.actions";

const mapStateToProps = (state) => ({
  personalBookings: state.calendar.personalBookings,
  personalStats: state.calendar.personalStats,
});

const mapDispatchToProps = dispatch => {
  return {
    getPersonalBookings: (year, month) => dispatch(getPersonalBookings(year,month)),
    getPersonalStats: () => dispatch(getPersonalStats()),
  }
};

export const CalendarContainer = connect(mapStateToProps, mapDispatchToProps)(CalendarPage);
