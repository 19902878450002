import React, {Component} from 'react';
import BreadcrumSection from "../Common/BreadcrumSection";
import QrReader from "react-qr-reader";
import * as Styled from "./Doors.style"
import { DoorsButton, LHButton } from "../../theme/globalStyles";
import OpenDoorsLoader from "../Common/OpenDoorsLoader";
import { MDBAlert } from "mdbreact";

class DoorsPage extends Component {

  constructor() {
    super();
    this.state = {
      open: false,
      message: 'Kliknij aby otworzyć drzwi wejściowe',
      state: 'info',
      error: null,
      submitting: false,
    };
    this.handleScan = this.handleScan.bind(this);
  }

  openDoors() {
    this.setState({
      ...this.state,
      submitting: true,
    })

    const {openDoor} = this.props;
    openDoor('TABLET').then((value) => this.setState((value)));

    setTimeout(() => {
        this.setState({
          open: false,
          message: 'Kliknij aby otworzyć drzwi wejściowe',
          state: 'ok',
          error: null,
          submitting: false,
        })
      }
      , 20000);
  }

  handleScan(data) {
    const {submitting} = this.state;
    if (submitting) {
      return
    }

    if(data) {

      this.setState({
        ...this.state,
        submitting: true,
      })

      const {openDoor} = this.props;
      openDoor('QR', data).then((value) => this.setState((value)));

      setTimeout(() => {
          this.setState({
            open: false,
            message: 'Kliknij aby otworzyć drzwi wejściowe',
            state: 'ok',
            submitting: false,
            error: null,
          })
        }
        , 20000);

    } else {
      return false
    }
  };

  handleError(err) {
    console.error(err);
  };

  render() {
    const { open, message, error, submitting} = this.state;

    return (
      <>
        <Styled.HeaderWrap>
          <BreadcrumSection
            title="LeśnaHub"
            subtitle="Panel na drzwiach"
            icon="building"
          />
        </Styled.HeaderWrap>
        <Styled.Tiles>
          <Styled.Tile>
            <Styled.OpenContainer>
              {submitting &&
                <OpenDoorsLoader />
              }
              {open && (
                <>
                  <Styled.SendWrap>
                    <Styled.SendIcon/>
                    <h3>Drzwi są otwarte przez 20 sekund</h3>
                    <p>Pociągnij mocno za klamkę!</p>
                  </Styled.SendWrap>
                </>
              )}

              {error === 'TOKEN EXPIRED' &&
                <Styled.QrCodeError>Twój QR kod stracił ważność. Zaloguj się ponownie aby wygenerować nowy</Styled.QrCodeError>
              }
              {error === 'USER INACTIVE' &&
                <Styled.QrCodeError>Twoje konto jest nieaktywne. Skontaktuj się z administratorem aby uzyskać więcej informacji.</Styled.QrCodeError>
              }
              {error === 'UNKNOWN CODE' &&
                <Styled.QrCodeError>Nie możemy rozpoznać Twojego QR kodu. Upewnij się, że korzystasz ze swojego indywidualnego kodu, na swoim koncie użytkownika.</Styled.QrCodeError>
              }
              {!open && (
                <Styled.Form onSubmit={(e) => e.preventDefault()}>
                  <div className="text-center">
                    <MDBAlert color="info"><h5>Zeskanuj kod QR ze swojego profilu użytkownika</h5></MDBAlert>
                    <QrReader
                      delay={300}
                      onError={this.handleError}
                      onScan={this.handleScan}
                      facingMode={"user"}
                      style={{width: "100%", marginTop: 50}}
                    />
                  </div>
                </Styled.Form>
              )}
            </Styled.OpenContainer>
          </Styled.Tile>
        </Styled.Tiles>
      </>
    )
  }
}
export default DoorsPage;