import {connect} from "react-redux";
import {RoomsPage} from "./Rooms.page";
import {getPersonalBookings} from "../Calendar/Calendar.actions";
import {removeRoomBooking} from './Rooms.actions';

const mapStateToProps = (state) => ({
  personalBookings: state.calendar.personalBookings,
});

const mapDispatchToProps = dispatch => {
  return {
    getPersonalBookings: (year, month) => dispatch(getPersonalBookings(year,month)),
    removeRoomBooking: (id) => dispatch(removeRoomBooking(id)),
  }
};

export const RoomsContainer = connect(mapStateToProps, mapDispatchToProps)(RoomsPage);
