import styled from "styled-components";

export const Tiles = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  background-color: #fff;
  border-radius: 4px;
  @media(max-width: 1100px){
    flex-direction: column;
  }
`
export const WallTiles = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  background-color: #fff;
  border-radius: 4px;
`
export const Tile = styled.div`
  background-color: ${({ theme }) => theme.background};
  width: 100%;
  padding: 20px 20px 40px;
  border: 2px solid ${ props => props.promoted ? "var(--main)" : "transparent"};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 4px;
  position: relative;
  p {
    margin-bottom: 0;
  }
  a:hover {
    color: var(--main);
    opacity: 1;
  }
  &:nth-child(1) { 
    padding: 0 40px;
    margin: 40px 0;
    border-right: 2px dashed rgba(0,0,0,0.1);
    @media(max-width: 1100px){
      border-right: none;
      border-bottom: 2px dashed rgba(0,0,0,0.1);
      margin: 0 40px;
      padding: 40px 0;
      width: calc(100% - 80px);
    }
    @media(max-width: 600px) {
      margin: 0 20px;
      width: calc(100% - 40px);
    }
  }
`
export const Form = styled.form`
  input, textarea {
    padding: 12px 0 12px 20px !important;
    width: calc(100% - 20px);
    background-color: #F1F2F8 !important;
    height: auto !important;
    border: none; 
    border-bottom: 1px solid #ced4da !important;
  }
  input {
    border-radius: 50px !important;
  }
  textarea {
    border-radius: 12px !important;
 } 
  label {
    left: 20px;
  }
  button {
    width: 100%;
    margin: 0;
  }
`
export const ContactData = styled.div`
  span {
    text-transform: uppercase;
    font-weight: bold;
    opacity: .5;
    display: block;
    margin: 20px 0 10px;
  }
`
export const Socials = styled.div`
  margin-top: 20px;
  ul {
        display: flex;
        padding: 0;
        margin: 0;
      li {
        list-style: none;
        padding: 0 20px 0 0;
        a {
          opacity: .5;
          @media(max-width: 500px) {
            font-size: 14px;
          }
        }
      }
  }
`
export const SendWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;  
  flex-direction: column;
  height: 100%;
  padding: 50px 0;
`
export const SendIcon = styled.div`
  height: 80px;
  width: 80px;
  margin-bottom: 40px;
  border-radius: 50%;
  position: relative;
  &:before {
    content: '';
    display: block;
    background-color: var(--main);
    opacity: .1;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:after {
    content: '';
    height: 30px;
    width: 80px;
    border-bottom: 8px solid var(--main);
    border-left: 8px solid var(--main);
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% + 10px);
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`
export const QrCodeError = styled.div`
  border: 2px solid red;
  border-radius: 4px;
  margin-bottom: 30px;
  background-color: #fbdbe0;
  padding: 10px;
`
export const OpenContainer = styled.div`
  position: relative;
  padding: 8px;
  min-height: 300px;
`
export const HeaderWrap = styled.div`
  padding-left: 10px;
`
