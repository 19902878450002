import React, {Component} from 'react';
import BreadcrumSection from "../Common/BreadcrumSection";
import {
  MDBAlert,
  MDBInput
} from "mdbreact";
import * as Styled from "./ContactForm.style"
import {LHButton} from "../../theme/globalStyles";

class ContactFormPage extends Component {

  constructor() {
    super();
    this.state = {
      subject: '',
      message: '',
      sent: false,
    };
  }

  submitForm() {
    const {subject, message, submitting} = this.state;
    if (submitting) {
      return false;
    }
    const {submitMessage} = this.props;
    submitMessage(subject, message);
    this.setState({sent: true});
  }

  render() {
    const {subject, message, sent} = this.state;

    return (
      <>
        <BreadcrumSection
          title="Kontakt"
          subtitle="Lorem ipsum dolor sit amet"
          icon="paper-plane"
        />
        <Styled.Tiles>
          <Styled.Tile>
            <div>
              <h1>LeśnaHub</h1>
              <p>Jak możemy Ci pomóc?</p>
            </div>
            <Styled.ContactData>
              <div>
                <span>Lokalizacja</span>
                <div>
                  Leśna 1
                  <br/>
                  81-876 Sopot
                </div>
                <span>Godziny pracy</span>
                <div>
                  Poniedziałek - Piątek (wkrótce 24/7)
                  <br/>
                  9:00 - 18:00
                </div>
                <br/>
                <div>
                  603 113 673
                </div>
                <div>
                  <a href="mailto:biuro@lesnahub.pl">biuro@lesnahub.pl</a>
                </div>
              </div>
            </Styled.ContactData>
            <Styled.Socials>
              <ul>
                <li><a href="https://www.facebook.com/lesnahub/" target="_blank" rel="noopener noreferrer">Facebook</a>
                </li>
                <li><a href="https://www.instagram.com/lesnahub/" target="_blank"
                       rel="noopener noreferrer">Instagram</a></li>
                <li><a href="https://www.linkedin.com/company/le%C5%9Bna-hub/" target="_blank"
                       rel="noopener noreferrer">LinkedIn</a></li>
              </ul>
            </Styled.Socials>
          </Styled.Tile>
          <Styled.Tile>
            <h2>Formularz kontaktowy</h2>

            {sent && (
              <>
                <Styled.SendWrap>
                  <Styled.SendIcon />
                  <h3>Dziękujemy za wiadomość!</h3>
                  <p>Odpowiemy tak szybko jak to będzie możliwe.</p>
                </Styled.SendWrap>
              </>
            )}
            {!sent && (
              <Styled.Form onSubmit={(e) => e.preventDefault()}>
                <MDBInput
                  label="Temat"
                  group
                  type="text"
                  validate
                  value={subject}
                  onChange={(e) => this.setState({subject: e.currentTarget.value})}
                />
                <MDBInput
                  type="textarea"
                  rows="8"
                  label="Wiadomość"
                  group
                  validate
                  value={message}
                  onChange={(e) => this.setState({message: e.currentTarget.value})}
                />
                <div className="text-center mt-4">
                  <LHButton type="submit" onClick={() => this.submitForm()}>
                    Wyślij
                  </LHButton>
                </div>
              </Styled.Form>
            )}
          </Styled.Tile>
        </Styled.Tiles>

      </>
    )
  }
}

export default ContactFormPage;
