import React, {Component} from 'react';
import BreadcrumSection from "../Common/BreadcrumSection";
import {
  MDBAlert,
  MDBCardText,
  MDBCardTitle, MDBInput,
} from "mdbreact";
import * as Styled from "./VirtualOffice.style";
import {LHButton} from "../../theme/globalStyles";

class VirtualOfficePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      plan: '',
      subject: '',
      message: '',
      sent: false,
    };
  }

  showForm = () => {
    this.setState({
      showForm: true,
    })
  }
  handleChange = changeEvent => {
    this.setState({
      [changeEvent.target.name]: changeEvent.target.value
    });
  };

  submitForm () {
    const {plan, subject, message, sent} = this.state;
    if (sent) {
      return false;
    }

    const {submitMessage} = this.props;
    submitMessage(plan, subject, message);
    this.setState({showForm: false, sent: true});
  }

  render() {
    return (
      <>
        <BreadcrumSection
          title="Wirtualne biuro"
          subtitle="Lorem ipsum dolor sit amet"
          icon="building"
        />
        <Styled.Tiles>
          <Styled.Tile>
            <div>
              <Styled.TileTitle>
                <div>
                  <MDBCardTitle>Wirtualne biuro</MDBCardTitle>
                  <MDBCardText>
                    Wirtualne biuro pozwala na korzystanie z adresu naszej lokalizacji bez dodatkowych kosztów wynajmu
                    przestrzeni biurowej oraz zatrudniania pracowników do jego stałej obsługi. Jest to idealne
                    rozwiązanie dla osób posiadających jednoosobową działalność gospodarczą lub dla start-upów.
                  </MDBCardText>
                </div>
                {!this.state.showForm &&
                <Styled.PlanPrice>
                  <strong>od 100 zł / miesiąc</strong>
                </Styled.PlanPrice>
                }
              </Styled.TileTitle>

              {!this.state.showForm &&
              <Styled.TilePerks>
                <ul>
                  <Styled.PlanPerk><strong>Prestiżowy adres Twojej firmy</strong></Styled.PlanPerk>
                  <Styled.PlanPerk><strong>Odbiór Twojej korespondencji</strong></Styled.PlanPerk>
                  <Styled.PlanPerk><strong>Powiadomienia SMS lub telefoniczne</strong></Styled.PlanPerk>
                </ul>
              </Styled.TilePerks>
              }
            </div>

            {this.state.sent && (
              <MDBAlert color="success">
                Dziękujemy za przesłanie zapytania. Skontaktujemy się z Tobą niebawem!
              </MDBAlert>
            )}

            {!this.state.showForm && !this.state.sent &&
            <div>
              <LHButton onClick={this.showForm}>Wybierz plan</LHButton>
            </div>
            }
            {this.state.showForm &&
            <Styled.FormWrap>
              <div>
                <Styled.TileAlert>
                  Rejestracja wirtualnego biura w naszym obiekcie dobywa się osobiście, daj nam znać, że jesteś
                  zainteresowany wypełniając formularz poniżej.
                  Skontaktujemy się z Tobą tak szybko jak to możliwe
                </Styled.TileAlert>
                <Styled.Form onSubmit={(e) => e.preventDefault()}>
                  <div>
                    <Styled.FormLabel>
                      <span>1</span>
                      Wybierz pakiet
                    </Styled.FormLabel>
                    <Styled.FormTiles>
                      <Styled.FormTile>
                        <Styled.FormRadio
                          type="radio"
                          name="plan"
                          value="biuro-miesiac-140"
                          checked={this.state.plan === "biuro-miesiac-140"}
                          onChange={this.handleChange}/>
                        <Styled.FormRadioText>
                          <Styled.FormRadioPrice>140zł</Styled.FormRadioPrice>
                          MIESIĘCZNY
                          <span>( 140 zł/miesiąc )</span>
                        </Styled.FormRadioText>
                      </Styled.FormTile>
                      <Styled.FormTile>
                        <Styled.FormRadio
                          type="radio"
                          name="plan"
                          value="biuro-pol-roku-720"
                          checked={this.state.plan === "biuro-pol-roku-720"}
                          onChange={this.handleChange}/>
                        <Styled.FormRadioText>
                          <Styled.FormRadioPrice>720zł</Styled.FormRadioPrice>
                          6-MIESIĘCZNY
                          <span>( 120 zł/miesiąc )</span>
                        </Styled.FormRadioText>
                      </Styled.FormTile>
                      <Styled.FormTile>
                        <Styled.FormRadio
                          type="radio"
                          name="plan"
                          value="biuro-rok-1200"
                          checked={this.state.plan === "biuro-rok-1200"}
                          onChange={this.handleChange}/>
                        <Styled.FormRadioText>
                          <Styled.FormRadioPrice>1200zł</Styled.FormRadioPrice>
                          ROCZNY
                          <span>( 100 zł/miesiąc )</span>
                        </Styled.FormRadioText>
                      </Styled.FormTile>
                    </Styled.FormTiles>
                  </div>
                  <Styled.FormLabel>
                    <span>2</span>
                    Dane kontaktowe
                  </Styled.FormLabel>
                  <MDBInput
                    label="Temat"
                    group
                    type="text"
                    validate
                    name="subject"
                    onChange={this.handleChange}
                  />
                  <MDBInput
                    type="textarea"
                    rows="8"
                    label="Wiadomość"
                    group
                    validate
                    name="message"
                    onChange={(e) => this.setState({message: e.currentTarget.value})}
                  />
                  <div>
                    <LHButton type="submit" onClick={() => this.submitForm()}>Wyślij</LHButton>
                  </div>
                </Styled.Form>
              </div>
            </Styled.FormWrap>
            }
          </Styled.Tile>
        </Styled.Tiles>
      </>
    )
  }
}

export default VirtualOfficePage;
