import {getRoomBookings, ROOM_ACTIONS} from "./Rooms.actions";
import {connect} from "react-redux";
import {RoomPage} from "./Room.page";

const mapStateToProps = (state) => ({
  isModalVisible: state.rooms.booking.isModalVisible,
  bookings: state.rooms.bookings,
});

const mapDispatchToProps = dispatch => {
  return {
    setRoomId: (id) => dispatch({ type: ROOM_ACTIONS.SET_ROOM_ID, payload: {id} }),
    setBookingDate: (date) => dispatch({ type: ROOM_ACTIONS.SET_BOOKING_DATE, payload: {date} }),
    getRoomBookings: (id, bookingStart, bookingEnd) => dispatch(getRoomBookings(id, bookingStart, bookingEnd)),
    showModal: () => dispatch({ type: ROOM_ACTIONS.SHOW_ROOM_MODAL }),
  }
};

export const RoomContainer = connect(mapStateToProps, mapDispatchToProps)(RoomPage);
