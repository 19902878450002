import {ViewState} from "@devexpress/dx-react-scheduler";
import {
  Appointments,
  DateNavigator,
  MonthView,
  Scheduler,
  Toolbar
} from "@devexpress/dx-react-scheduler-material-ui";
import {AvailableDesksAppointment} from "./AvailableDesksAppointment";
import React, {Component} from "react";
import PropTypes from "prop-types";
import {getDailyDesksAvailability} from "../Desks.actions";
import {connect} from "react-redux";
import moment from "moment";

class SchedulerComponent extends Component {

  constructor() {
    super();
    this.updateCurrentView = this.updateCurrentView.bind(this);
  }

  componentDidMount() {
    this.updateCurrentView(moment());
  }

  updateCurrentView(date){
    const currentView = moment(date);
    const {getDailyDesksAvailability} = this.props;
    getDailyDesksAvailability(currentView.format('YYYY'), currentView.format('MM'));
  }

  render() {
    const {dailyAvailability} = this.props;
    const data = dailyAvailability.filter((day) => day.availableDesks > 0).map((day) => {
      return {
        startDate: moment(day.date).format('YYYY-MM-DD 00:00'),
        endDate: moment(day.date).format('YYYY-MM-DD 23:59'),
        title: `Biurka są dostępne`,
        allDay: true
      }
    });

    return (
      <Scheduler
        data={data}
        locale="pl-PL"
        firstDayOfWeek={1}
      >
        <ViewState
          defaultCurrentDate={moment().format()}
          onCurrentDateChange={this.updateCurrentView}
        />
        <MonthView/>
        <Toolbar/>
        <DateNavigator />
        <Appointments
          appointmentComponent={AvailableDesksAppointment}
        />
      </Scheduler>
    )
  }
}

SchedulerComponent.propTypes = {
  dailyAvailability: PropTypes.array,
};

const mapStateToProps = (state) => ({
  dailyAvailability: state.desks.dailyAvailability,
});

const mapDispatchToProps = dispatch => {
  return {
    getDailyDesksAvailability: (year, month) => dispatch(
      getDailyDesksAvailability(year, month)
    ),
  }
};

export const DeskSchedulerComponent = connect(mapStateToProps, mapDispatchToProps)(SchedulerComponent);
