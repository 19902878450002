import React, {Component} from 'react';
import {
  MDBAlert,
  MDBInput, MDBLink,
} from "mdbreact";
import axios from "axios";
import {backendHost} from "../../common/helpers/http";
import LoginPanel from "../Common/LoginPanel";
import { LHButton} from "../../theme/globalStyles";
import * as Styled from "./Registration.style";

export class RegistrationPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: null,
      password: null,
      passwordRepeat: null,
      regulations: null,
      showConfirmEmailMsg: false,
      errorMsg: null,
    }
    this.handleFormSubmit= this.handleFormSubmit.bind(this);
  }

  handleFormSubmit(e) {
    e.preventDefault();
    this.submitForm();
  }

  async submitForm() {
    const {username, password, passwordRepeat, regulations} = this.state;
    if (!password || !passwordRepeat || password !== passwordRepeat) {
      this.setState({errorMsg: 'Podane hasła różnią się od siebie'})
      return;
    }
    if (!regulations) {
      this.setState({errorMsg: 'Aby korzystać z naszego serwisu musisz zaakceptować regulamin'})
      return;
    }
    try {
      await axios.post(`${backendHost}/user/register`, {
        username, password
      });
      this.setState({ showConfirmEmailMsg: true, errorMsg: null });
    } catch (error) {
      const response = error.response.data;
      this.setState({errorMsg: response.message})
    }
  }

  render() {
    const {errorMsg, showConfirmEmailMsg} = this.state;

    return (
        <LoginPanel>
          <Styled.RegisterForm onSubmit={this.handleFormSubmit}>
            <h1>Załóż konto w systemie</h1>

            {errorMsg &&
            <MDBAlert color="danger">
              {errorMsg}
            </MDBAlert>
            }

            {showConfirmEmailMsg &&
            <MDBAlert color="info">
              Dziękujemy za rejestrację! <br/>
              Na Twój adres email wysłaliśmny link aktywacyjny
            </MDBAlert>
            }

            {!showConfirmEmailMsg &&
            <>
              <div className="grey-text">
                <MDBInput
                    label="Adres e-mail"
                    group
                    type="email"
                    validate
                    error="wrong"
                    success="right"
                    onChange={(e) => this.setState({username: e.currentTarget.value})}
                />
                <MDBInput
                    label="Hasło"
                    group
                    type="password"
                    onChange={(e) => this.setState({password: e.currentTarget.value})}
                    validate
                />
                <MDBInput
                    label="Powtórz hasło"
                    group
                    type="password"
                    onChange={(e) => this.setState({passwordRepeat: e.currentTarget.value})}
                    validate
                />
                <Styled.FormCheckbox>
                  <label>
                    <input
                      type="checkbox"
                      required
                      onChange={(e) => this.setState({regulations: e.currentTarget.value})}
                    />
                    <span></span>
                    Akceptuję
                    <MDBLink to="/">regulamin</MDBLink>
                    serwisu LeśnaHub
                  </label>
                </Styled.FormCheckbox>
              </div>
              <div className="text-center">
                <LHButton type="submit">Zarejestruj się</LHButton>
              </div>
            </>
            }
          </Styled.RegisterForm>
          <Styled.RegisterFormLogin>
            <span>Masz juz konto w naszym systemie?</span>
            <MDBLink to="/">Zaloguj się</MDBLink>
          </Styled.RegisterFormLogin>
        </LoginPanel>
    )
  }
}
