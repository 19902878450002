import styled from "styled-components";

export const AppointmentNotes = styled.div`
  padding: 20px;
  display: flex;
`
export const AppointmentNotesLabel = styled.div`
  font-size: 12px;
  font-weight: bold;
  position: relative;
  top: 3px;
`
export const AppointmentMessage = styled.div`
  padding-left: 5px;
`



