import axios from "axios";
import {requestErrorHandler} from "../../common/helpers/errorHandler";
import {backendHost} from "../../common/helpers/http";
import moment from "moment";

export const DESK_ACTIONS = {
  UPDATE_DAILY_DESKS_AVAILABILITY: 'UPDATE_DAILY_DESKS_AVAILABILITY',
  UPDATE_MONTHLY_DESKS_AVAILABILITY: 'UPDATE_MONTHLY_DESKS_AVAILABILITY',
  SHOW_DAILY_DESK_BOOKING_MODAL: 'SHOW_DAILY_DESK_BOOKING_MODAL',
  SHOW_MONTHLY_DESK_BOOKING_MODAL: 'SHOW_MONTHLY_DESK_BOOKING_MODAL',
  HIDE_DAILY_DESK_BOOKING_MODAL: 'HIDE_DAILY_DESK_BOOKING_MODAL',
  HIDE_MONTHLY_DESK_BOOKING_MODAL: 'HIDE_MONTHLY_DESK_BOOKING_MODAL',
  UPDATE_SELECTED_PACKAGE: 'UPDATE_SELECTED_PACKAGE',
};

export const getDailyDesksAvailability = (selectedYear, selectedMonth) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {accessToken} = state.user;
    const response = await axios.get(
      `${backendHost}/desk/availability?selectedYear=${selectedYear}&selectedMonth=${selectedMonth}`,
      {
        headers: {'Authorization': `Bearer ${accessToken}`}
      });

    const {data} = response;
    dispatch({type: DESK_ACTIONS.UPDATE_DAILY_DESKS_AVAILABILITY, payload: data});
    return data;
  } catch (error) {
    requestErrorHandler(error);
    return false;
  }
};

export const createBooking = (bookingStart, bookingLength, quantity) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {accessToken} = state.user;
    const response = await axios.post(`${backendHost}/desk/booking`, {
      bookingStart,
      bookingLength,
      quantity,
      itemType: 'DESK',
    }, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });
    const {data} = response;
    dispatch(
      getDailyDesksAvailability(moment(bookingStart).format('YYYY'), moment(bookingStart).format('MM'))
    );
    return data;
  } catch (error) {
    requestErrorHandler(error);
    throw error.response.data;
  }
};

export const getMonthlyDesksAvailability = (bookingStart) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {accessToken} = state.user;
    const response = await axios.get(
      `${backendHost}/desk/availability/monthly?bookingStart=${bookingStart}`,
      {
        headers: {'Authorization': `Bearer ${accessToken}`}
      });

    const {data} = response;
    dispatch({type: DESK_ACTIONS.UPDATE_MONTHLY_DESKS_AVAILABILITY, payload: data});
    return data;
  } catch (error) {
    requestErrorHandler(error);
    return false;
  }
};

export const createMonthlyBooking = (bookingStart, itemId, annualContract) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {accessToken} = state.user;
    const response = await axios.post(`${backendHost}/desk/booking`, {
      bookingStart,
      itemId,
      annualContract,
      itemType: 'DESK',
    }, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });
    const {data} = response;
    return data;
  } catch (error) {
    requestErrorHandler(error);
    return false;
  }
};
