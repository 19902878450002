import React, {Component} from 'react';
import BreadcrumSection from "../Common/BreadcrumSection";
import * as Styled from "./Doors.style"
import {DoorsButton} from "../../theme/globalStyles";
import QRCode from 'qrcode';
import jwt_decode from "jwt-decode";
import OpenDoorsLoader from "../Common/OpenDoorsLoader";

class DoorsPage extends Component {

  constructor() {
    super();
    this.state = {
      open: false,
      message: 'Kliknij aby otworzyć drzwi wejściowe',
      state: 'info',
      qrcode: null,
      error: null,
      submitting: false,
    };
    this.GenerateQRCode = this.GenerateQRCode.bind(this)
  }

  GenerateQRCode(data) {
    QRCode.toDataURL(data,{width: 500} ,(err, string) => {
      if (err) throw err
      this.setState({
          ...this.state,
          qrcode: string,
        }
      )
    })
  }

  openDoors() {
    this.setState({...this.state, submitting: true});

    const {openDoor, user} = this.props;
    openDoor('BUTTON', user.accessToken).then((value) => {
      this.setState((value))
    });
    setTimeout(() => {
        this.setState({
          open: false,
          message: 'Kliknij aby otworzyć drzwi wejściowe',
          state: 'info',
          error: null,
        })}
      , 20000);
  }

  componentDidMount() {
    const {user} = this.props;
    var decoded = jwt_decode(user.accessToken);
    const userData = {
      id: user.id,
      exp: decoded.exp,
    }
    this.GenerateQRCode(JSON.stringify(userData))
  }

  render() {
    const {open, message, state, submitting, error} = this.state;
    return (
      <>
        <BreadcrumSection
          title="Wejście do Budynku"
          subtitle="Tutaj otworzysz zdalnie drzwi wejściowe. "
          icon="building"
        />
        <Styled.Tiles>
          <Styled.Tile>
            <Styled.OpenContainer>

              {error === 'TOKEN EXPIRED' &&
                <Styled.QrCodeError>Twój QR kod stracił ważność. Zaloguj się ponownie aby wygenerować nowy</Styled.QrCodeError>
              }
              {error === 'USER INACTIVE' &&
                <Styled.QrCodeError>Twoje konto jest nieaktywne. Skontaktuj się z administratorem aby uzyskać więcej informacji.</Styled.QrCodeError>
              }
              {error === 'UNKNOWN CODE' &&
                <Styled.QrCodeError>Nie możemy rozpoznać Twojego QR kodu. Upewnij się, że korzystasz ze swojego indywidualnego kodu, na swoim koncie użytkownika.</Styled.QrCodeError>
              }

              {submitting &&
                <OpenDoorsLoader />
              }

              {open && (
                <>
                  <Styled.SendWrap>
                    <Styled.SendIcon/>
                    <h3>Drzwi są otwarte przez 20 sekund</h3>
                    <p>Pociągnij mocno za klamkę!</p>
                    <p>Ostatnia aktywność: {message}</p>
                  </Styled.SendWrap>
                </>
              )}
              {!open && (
                <Styled.Form onSubmit={(e) => e.preventDefault()}>

                  {this.state.qrcode &&
                    <img alt="qr logo" src={this.state.qrcode} style={{width: "100%"}}/>
                  }

                  <div className="text-center mt-4">
                    <DoorsButton type="submit" onClick={() => this.openDoors()}>
                      Otwórz
                    </DoorsButton>
                  </div>

                </Styled.Form>
              )}
            </Styled.OpenContainer>
          </Styled.Tile>
          <Styled.Tile>
            <div>
              <h1>LeśnaHub</h1>
              <p>Jak wejść do budynku LeśnaHub?</p>
            </div>
            <Styled.ContactData>
              <div>
                <span>Lokalizacja</span>
                <div>
                  Leśna 1
                  <br/>
                  81-876 Sopot
                </div>
                <span>Godziny pracy</span>
                <div>
                  Poniedziałek - Piątek (wkrótce 24/7)
                  <br/>
                  9:00 - 18:00
                </div>
                <br/>
                <div>
                  603 113 673
                </div>
                <div>
                  <a href="mailto:biuro@lesnahub.pl">biuro@lesnahub.pl</a>
                </div>
              </div>
            </Styled.ContactData>
            <Styled.Socials>
              <ul>
                <li><a href="https://www.facebook.com/lesnahub/" target="_blank" rel="noopener noreferrer">Facebook</a>
                </li>
                <li><a href="https://www.instagram.com/lesnahub/" target="_blank"
                       rel="noopener noreferrer">Instagram</a></li>
                <li><a href="https://www.linkedin.com/company/le%C5%9Bna-hub/" target="_blank"
                       rel="noopener noreferrer">LinkedIn</a></li>
              </ul>
            </Styled.Socials>
          </Styled.Tile>

        </Styled.Tiles>

      </>
    )
  }
}

export default DoorsPage;
