import * as React from 'react';
import {connect} from "react-redux";
import { Appointments } from '@devexpress/dx-react-scheduler-material-ui';
import {DESK_ACTIONS} from "../Desks.actions";
import moment from "moment";
import PropTypes from "prop-types";

const AvailableDesksAppointmentConfig = ({
  children, style, showModal, ...restProps
}) => (
  <Appointments.Appointment
    {...restProps}
    style={{
      ...style,
      backgroundColor: 'rgb(135, 123, 237)',
      color: 'rgb(135, 123, 237)',
      borderRadius: '4px',
      margin: '8px',
      width: 'calc(100% - 8px)',
      fontSize: '14px',
      position: 'relative',
      left: '-3px',
      bottom: '-5px',
      height: '50px',
      lineHeight: '46px',
    }}
    onClick={(day) => showModal(moment(day.data.startDate).format('YYYY-MM-DD'))}
  >
    {children}
  </Appointments.Appointment>
);

AvailableDesksAppointmentConfig.propTypes = {
  children: PropTypes.array,
  style: PropTypes.object,
  showModal: PropTypes.func,
};

const mapDispatchToProps = dispatch => {
  return {
    showModal: (startDate) => dispatch({ type: DESK_ACTIONS.SHOW_DAILY_DESK_BOOKING_MODAL, payload: startDate }),
  }
};

export const AvailableDesksAppointment = connect(null, mapDispatchToProps)(AvailableDesksAppointmentConfig);
