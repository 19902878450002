import {connect} from "react-redux";
import DoorsPage from "./Doors.page";
import {openDoor} from "./Doors.actions";

const mapStateToProps = (state) => ({
  user: state.user.id,
});

const mapDispatchToProps = dispatch => {
  return {
    openDoor: (type, data) => dispatch(openDoor(type, data)),
  }
};

export const DoorsTabletContainer = connect(mapStateToProps, mapDispatchToProps)(DoorsPage);
