import React, {Component} from 'react';
import BreadcrumSection from "../Common/BreadcrumSection";
import * as Styled from "./Screensaver.style"
import {LHButton} from "../../theme/globalStyles";
import logo from "../../assets/250px-LH-logo-light-bg.png";

class ScreensaverPage extends Component {

    constructor() {
        super();
    }

    handleError(err) {
        console.error(err);
    };

    render() {

        return (
            <>
                <BreadcrumSection
                    title="LeśnaHub"
                    subtitle="Panel"
                    icon="door-open"
                />
                <Styled.Tiles>
                    <Styled.Tile>
                            <>
                                <Styled.SendWrap>
                                    <img alt="app logo" src={logo}/>
                                    <h3>Zapukaj w szybę i pokaż mi swój QR!</h3>
                                </Styled.SendWrap>
                            </>

                    </Styled.Tile>
                    <Styled.Tile>
                        <div>
                            <h1>LeśnaHub</h1>
                             <h1><a href="tel:+48534915862">+48 534 915 862</a></h1>
                        </div>

                        <Styled.Socials>
                            <ul>
                                <li><a href="tel:+48534915862">+48 534 915 862</a></li>
                                <li><a href="https://www.facebook.com/lesnahub/" target="_blank" rel="noopener noreferrer">Facebook</a>
                                </li>
                                <li><a href="https://www.instagram.com/lesnahub/" target="_blank"
                                       rel="noopener noreferrer">Instagram</a></li>
                                <li><a href="https://www.linkedin.com/company/le%C5%9Bna-hub/" target="_blank"
                                       rel="noopener noreferrer">LinkedIn</a></li>
                            </ul>
                        </Styled.Socials>
                    </Styled.Tile>

                </Styled.Tiles>

            </>
        )
    }
}

export default ScreensaverPage;
