import styled, {css} from "styled-components";
import logo from "../../assets/250px-LH-logo-light-bg.png";

export const NavWrap = styled.div`
  .sidebar-fixed {
    box-shadow: none;
    width: 270px;
    border-right: 1px solid #b6bde6;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    z-index: 1000;
    transition: width .2s;
  }
  .list-group-item {
    border: none;
    margin: 25px 0;
    color: #222731;
    opacity: .65;
    transition: .2s;
    white-space: nowrap;
    span {
      opacity: 1;
      transition: opacity .1s;
    }
    i {
      font-size: 24px;
      position: relative;
      top: 3px;
      opacity: .8;
      transition: .2s;
    }
    &:hover {
      opacity: 1;
    }
  }
  .active {
    box-shadow: none;
    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      right: -24px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      top: calc(50% + 12px);
      transform: translateY(-50%);
      width: 3px;
      background-color: var(--main);
      height: 34px; 
    }
    .list-group-item {
      opacity: 1;
      i {
        color: var(--main);
        opacity: 1;
      }
    }
  } 
  ${props => props.collapsed && css`
    .sidebar-fixed {
      width: 110px;
    }
    .list-group-item {
      span {
        opacity: 0;
        pointer-events: none;
      }
    }
    ${NavLogo} {
      width: 45px;
      background-size: auto 45px;
      @media(max-width: 800px) {
        width: 200px;
        background-size: auto 45px;
      }
    }
    ${NavToggle} {
      &:after {
        transform: translate(-50%, -50%) rotate(-135deg);
        left: calc(50% - 1px);
        transition: transform .4s;
      } 
    }
    ${MobileToggle} {
      background-color: #222631;
      &:after {
        transform: translateY(0) rotate(0deg);
      } 
      &:before {
        transform: translateY(0) rotate(0deg);
      } 
    }
  `}
  @media(max-width: 800px) {
    .sidebar-fixed {
      width: 100%;
      border: none;
      height: ${props => props.collapsed ? '60px' : 'auto'};
      overflow: hidden;
      box-shadow: 0 5px 10px  0 rgba(0,0,0,0.05);
      padding: 0 10px;
    }
    .logo-wrapper {
      padding-left: 6px;
    }
  }
`
export const NavFooter = styled.div`
  position: relative;
`
export const NavLogo = styled.div`
  margin-top: 24px;
  height: 45px;
  width: 200px;
  background-image: url(${logo}); 
  background-size: auto 45px;
  background-repeat: no-repeat;
  position: relative;
  left: -5px;
  transition: width .2s;
  @media(max-width: 800px) {
    margin-top: 8px;
    height: 30px;
    background-size: auto 30px !important;
    padding-left: 0;
  }
`
export const  NavToggle = styled.button`
  display: block;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: absolute;
  z-index: 10;
  right: -45px;
  bottom: 4px;
  background-color: #fff;
  border: 1px solid #b6bde6;
  outline: none;
  &:focus {
    outline: none;
  }
  &:after {
    content: '';
    display: block;
    height: 8px;
    width: 8px;
    border-left: 2px solid #222731;
    border-bottom: 2px solid #222731;
    opacity: .65;
    position: absolute;
    top: 50%;
    left: calc(50% + 1px);
    transform: translate(-50%, -50%) rotate(45deg);
    transition: transform .4s;
  }
  @media(max-width: 800px) {
    display: none;  
  }
`
export const MobileToggle = styled.div`
  position:  absolute;
  height: 19px;
  width: 30px;
  border-radius: 1px;
  background-color: transparent;
  border-top: 8px solid #fff;
  border-bottom: 8px solid #fff;
  top: 45px;
  right: 40px;
  display: none;
  cursor: pointer;
  transition: width .2s;
  &:after, &:before {
    content: '';
    display: block;
    height: 3px;
    width: 30px;
    background-color: #222631;    
    position: relative;
    top: -11px;
    transition: transform .2s;
  }
  &:before {
    top: 8px; 
  }
  &:after {
    transform: translateY(8px) rotate(45deg);
  } 
  &:before {
    transform: translateY(-8px) rotate(-45deg);
  } 
  
  @media(max-width: 800px) {
    display: block;  
    top: 20px;
    right: 20px;
  }
`
