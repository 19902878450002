import * as React from 'react';
import { Appointments } from '@devexpress/dx-react-scheduler-material-ui';

export const UnavailableSlotAppointment = ({
  children, style, ...restProps
}) => (
  <Appointments.Appointment
    {...restProps}
    style={{
      ...style,
      backgroundColor: 'rgb(222, 225, 232)',
      backgroundImage: 'repeating-linear-gradient(-45deg, transparent, transparent 35px, rgba(255,255,255,.5) 35px, rgba(255,255,255,.5) 70px)',
      borderRadius: '4px',
      textAlign: 'center',
      position: 'relative',
      left: '6px',
      top: '4px',
      margin: '2px 0',
      height: 'calc(100% - 8px)',
      fontSize: '14px',
    }}
  >
    {children}
  </Appointments.Appointment>
);
