import React from 'react';
import { MDBCardTitle, MDBCardText } from 'mdbreact';
import {LHButton, LHButtonOutline} from "../../../theme/globalStyles";
import * as Styled from '../Desks.style'

const DeskPlanTile = ({ name, description, price, children, perks, promoted, pickPlan, isPicked}) => {
  return (
    <>
      <Styled.DeskPlanDetails>
        <Styled.DeskPlanHeader>
        <Styled.TileTitle>
          <div>
            <MDBCardTitle>{ name }</MDBCardTitle>
            <MDBCardText>
              { description }
            </MDBCardText>
            <Styled.PlanPrice>
              <strong>{ price }</strong>
            </Styled.PlanPrice>
          </div>
        </Styled.TileTitle>
        </Styled.DeskPlanHeader>
        <div>
          {!isPicked &&
          <Styled.TilePerks>
            <ul>
              {perks.map((perk, index) => {
                  if (perk.type === "unavailable") {
                    return <Styled.PlanPerk key={index} unactive><s>{perk.name}</s></Styled.PlanPerk>
                  } else if (perk.type === "strong") {
                    return <Styled.PlanPerk key={index}><strong>{perk.name}</strong></Styled.PlanPerk>
                  }
                  return <Styled.PlanPerk key={index}>{perk.name}</Styled.PlanPerk>
                }
              )}
            </ul>
          </Styled.TilePerks>
          }
          {children}
        </div>
      </Styled.DeskPlanDetails>

      { !isPicked &&
      <div>
        { promoted ?
          <LHButton onClick={ pickPlan }>Wybierz plan</LHButton>
          :
          <LHButtonOutline onClick={ pickPlan }>Wybierz plan</LHButtonOutline>
        }
      </div>
      }
    </>
  )
}

export default DeskPlanTile;
