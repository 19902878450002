import React from 'react';
import PropTypes from "prop-types";
import styled from 'styled-components';
import {MDBIcon} from "mdbreact";

const StyledPageHeader = styled.div`
  display: flex;
  line-height: 1.5em;
  margin-bottom: 15px;
  align-items: center;
  padding: 10px 0;
  @media(max-width: 1100px) {
    margin-bottom: 0;
  }
`
const StyledPageHeaderTitle = styled.div`
  font-size: 1.4em;
  font-weight: bold;
  margin-top: 4px;
  @media(max-width: 1100px) {
    font-size: 1em; 
    margin-top: 0;
    line-height: 1em;
    margin-bottom: 2px;
  }
`
const StyledPageHeaderSubtitle = styled.div`
  font-size: .9em;
  opacity: .6;
  @media(max-width: 1100px) {
    font-size: 0.8em;
    line-height: 0.8em;
  }
`
const StyledPageHeaderIcon = styled.div`
  height: 48px;
  width: 48px;
  line-height: 48px;
  text-align: center;
  background-color: var(--main);
  color: #fff;
  border-radius: 4px;
  margin-right: 10px;
  font-size: 24px;
  i {
    margin-right: 0 !important;
  }
  @media(max-width: 1100px) {
    height: 32px;
    width: 32px;
    line-height: 32px; 
    font-size: 16px;
  }
`

const BreadcrumSection = ({title, subtitle, icon}) => {
  return (
    <>
      <StyledPageHeader>
        <StyledPageHeaderIcon>
          <MDBIcon far icon={icon} className="mr-3"/>
        </StyledPageHeaderIcon>
        <div>
          <StyledPageHeaderTitle>
            {title}
          </StyledPageHeaderTitle>
          <StyledPageHeaderSubtitle>
            {subtitle}
          </StyledPageHeaderSubtitle>
        </div>
      </StyledPageHeader>
    </>
  )
}

BreadcrumSection.propTypes = {
  page: PropTypes.string,
};

export default BreadcrumSection;

