import styled, { css } from "styled-components";
import coworkMap from "../../../assets/room1-new.svg"
import coworkMapNew from "../../../assets/room2.svg"

export const BookingWrap = styled.div`
  padding: 20px;
`
export const BookingFormFilter = styled.div`
  display: flex;
  border-radius: 4px;
  padding: 20px 0;
  & > div {
    flex: 1;
  }
  input, select {
    border-radius: 50px !important;
    padding: 12px 0 12px 20px !important;
    width: calc(100% - 20px);
    background-color: #F1F2F8 !important;
    height: auto !important;
    border: none; 
    border-bottom: 1px solid #ced4da !important;
  }
  label {
    left: 20px;
  }
`
export const BookingMap = styled.div`
  position: relative;
  background-color: #F1F2F8;
  background-image: url(${ coworkMap });
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 700px;
  min-width: 1100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px 20px 100px 0;
  border-radius: 4px;
  box-shadow: 
    0 -40px 0 0 #F1F2F8, 
    0 70px 0 0 #F1F2F8;

`
export const BookingMapRow = styled.div`
  display: flex;
  margin: 10px;
  position: relative;
  left: 60px;
  top: 29px;
  width: 800px;
  flex-wrap: wrap;
  ${props => props.top && css`
    width: 1000px;
   & > div:nth-child(1) {
      width: 58px;
      height: 94px;
      position: relative;
      left: -28px;
      top: -33px;
    }
   & > div:nth-child(2) {
      position: relative;
      left: 35px;
      top: -90px;
      transform: rotate(-28deg);
    }
   & > div:nth-child(3) {
      position: relative;
      left: 56px;
      top: -143px;
      transform: rotate(-14deg);
    }
   & > div:nth-child(4) {
      position: relative;
      left: 168px;
      top: -141px;
      transform: rotate(12deg);
    }
   & > div:nth-child(5) {
      position: relative;
      left: 187px;
      top: -88px;
      transform: rotate(27deg);
    }
  `}
 `

export const BookingMapDesk = styled.div`
  height: 60px;
  width: 92px;
  border-radius: 2px;
  opacity: .6;
  margin: 16px 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 18px;
  color: var(--main);
  &[data-status="free"]  {
        border: 2px solid var(--main);
        background-color: rgba(135,123,237,.3);
  }
  &[data-status="selected"]  {
        border: 2px solid #35C39B;
        background-color: rgba(53,195,155,.3);
        color: #35c39B;
  }
  &[data-status="busy"]  {
        border: 2px solid #c5caea;
        background-color: #F1F2F8;
        color: #c5caea;
        pointer-events: none;
    }
  &:hover {
    opacity: .7;
  }


`
export const BookingMapLegend = styled.div`
  display: flex;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  top: -100px;
 `
export const BookingLegendItem = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  &:before {
    content: '';
    display: inline-block;
    height: 30px;
    width: 30px;
    border-radius: 2px;
    margin-right: 10px;
    ${props => {
      if (props.status === "free") {
    return `
        border: 2px solid var(--main);
        background-color: rgba(135,123,237,.3);
    `
      } else if (props.status === "selected") {
      return `
        border: 2px solid #35C39B;
        background-color: rgba(53,195,155,.3);
          `
    } else {
      return `
        border: 2px solid #c5caea;
        background-color: #F1F2F8;
          `
    }
  }}
  }
`
export const DatePickerWrap = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
    margin-right: 20px;
  }
  input {
    width: calc(100% - 20px);
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem 1.75rem .375rem .75rem;
  }
`
export const FormLabel = styled.div`
  margin-bottom: 20px;
  span {
    display: inline-block;
    height: 26px;
    width: 26px;
    margin-right: 5px;
    background-color: #E6E3FC;
    color: var(--main);
    font-size: .9em;
    border-radius: 50px;
    text-align: center;
    line-height: 26px;
  }
`
export const BookingSummary = styled.div`
  display: flex;
  justify-content: space-between; 
  margin-right: 20px;
  align-items: flex-end;
  padding-top: 30px;
  border-top: 2px dashed rgba(0,0,0,0.1);
`
export const FormWrap = styled.div`
  position: relative;
`
export const BookingMapNew = styled.div`
  position: relative;
  background-color: #F1F2F8;
  background-image: url(${ coworkMapNew });
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 700px;
  min-width: 1100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px 20px 100px 0;
  border-radius: 4px;
  box-shadow: 
    0 -40px 0 0 #F1F2F8, 
    0 70px 0 0 #F1F2F8;

`
export const BookingMapColumns  = styled.div`
  display: flex;
`
export const BookingMapColumn = styled.div`
  display: flex;
  margin: 10px 41px 10px 40px;
  position: relative;
  top: -50px;
  left: -42px;
  flex-wrap: wrap;
  width: 120px;
 `
export const BookingMapNewDesk = styled.div`
  width: 42px;
  height: 64px;
  border-radius: 2px;
  opacity: .6;
  margin: 2px 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 18px;
  color: var(--main);
  &[data-status="free"]  {
        border: 2px solid var(--main);
        background-color: rgba(135,123,237,.3);
  }
  &[data-status="selected"]  {
        border: 2px solid #35C39B;
        background-color: rgba(53,195,155,.3);
        color: #35c39B;
  }
  &[data-status="busy"]  {
        border: 2px solid #c5caea;
        background-color: #F1F2F8;
        color: #c5caea;
        pointer-events: none;
    }
  &:hover {
    opacity: .7;
  }
  &.small-group:nth-child(5) {
    height: 44px;
    width: 100px;
  }
  &.alone-desk{
    height: 44px;
    width: 100px;
    transform: translate(200px,75px);
  }
`
export const FormTabs = styled.div`
  .nav-tabs {
    position:relative;
    z-index: 10;
    background-color: #fff;
    margin-right: 20px;
    box-shadow: 0 10px 12px -10px rgba(0,0,0,0.1);
  }
  .nav-link {
    border: none;
    padding: 20px 30px;
    border-bottom: 2px solid #fff;
    position: relative;
    top: -1px;
    &:hover {
      border-bottom: 2px solid #fff;
      color: var(--main);
    }
    &.active.active {
      border-bottom: 2px solid var(--main);
    }
  }
  
`

export const BookingMapOld = styled.div`
  position: relative;
  background-color: #F1F2F8;
  background-image: url(${ coworkMap });
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 700px;
  min-width: 1100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px 20px 100px 0;
  border-radius: 4px;
  box-shadow: 
    0 -40px 0 0 #F1F2F8, 
    0 70px 0 0 #F1F2F8;

`
export const BookingMapRowOld = styled.div`
  display: flex;
  margin: 10px;
  position: relative;
  left: -5px;
  top: 44px;
  & > div {
    align-self: ${props => props.top ? 'flex-end' : 'flex-start'};
  }
 `

export const BookingMapDeskOld = styled.div`
  height: 60px;
  width: 92px;
  border-radius: 2px;
  opacity: .6;
  margin: 5px 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 18px;
  color: var(--main);
  &[data-status="free"]  {
        border: 2px solid var(--main);
        background-color: rgba(135,123,237,.3);
  }
  &[data-status="selected"]  {
        border: 2px solid #35C39B;
        background-color: rgba(53,195,155,.3);
        color: #35c39B;
  }
  &[data-status="busy"]  {
        border: 2px solid #c5caea;
        background-color: #F1F2F8;
        color: #c5caea;
        pointer-events: none;
    }
  &:hover {
    opacity: .7;
  }
  &:nth-child(2) {
    width: 58px;
    height: 94px;
    margin-right: 52px;
    position: relative;
    right: 2px;
  }
  &:nth-child(3) {
    width: 58px;
    height: 94px;
    margin-left: 55px;
    margin-right: 4px;
  }
  &:nth-child(7) {
    width: 58px;
    height: 94px;
  }

`
