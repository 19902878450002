import React from 'react';
import {
    MDBCol,
    MDBContainer,
    MDBRow
} from "mdbreact";
import styled from "styled-components";
import loginBg from "../../assets/lh-app-login.png";
import logo from "../../assets/250px-LH-logo-light-bg.png";


const StyledLoginPanel = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  @media(max-width: 1000px) {
    align-items: center;
    justify-content: center;    
  }
`
const StyledLoginFormWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex: 2;
  background-color: ${({ theme }) => theme.background};
  .container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    .col {
      width: 480px;
      @media(max-width: 1000px) {
        width: auto;
      }
    }
      @media(max-width: 800px) {
      .col{
        max-width: 500px;
        width: 100%;
        padding: 0;
      }
      .row {
        width: 100%;
      }
      }
  }
  @media(max-width: 1000px) {
    max-width: 500px;
    margin: 8px;
    padding: 40px 0 20px;
    border-radius: 4px;
  }
  @media(max-width: 800px){
    min-height: 100vh;
    margin: 0;
    border-radius: 0;
    max-width: 100%;
  }
`
const StyledLoginFormLogo = styled.div`
  text-align: center;
  margin-bottom: 50px;
  img {
    width: 140px;
    @media(max-width: 720px){
      width: 110px;
    }
  }
  
`
const StyledLoginImage = styled.div`
  flex: 3;
  background-color: ${({ theme }) => theme.background};
  padding: 20px;
  position: relative;
  @media(max-width: 1000px) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    padding: 0;
  }
  @media(max-width: 800px) {
    display: none;
  }
  &:after {
      content: '';
      display: block;
      height: 100%;
      background-image: radial-gradient(transparent, rgb(30, 27, 58)), linear-gradient(to right, rgba(33, 27, 86, 0.6), transparent), url(${loginBg});
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      box-sizing: border-box;
      border-radius: 6px;
      @media(max-width:1000px) {
        border-radius: 0;
      }
}
`
const StyledLoginPanelSocials = styled.div`
  position: absolute;
  bottom: 20px;
  right: 50px;
  @media(max-width: 1000px) {
    display: none;
  }
  ul {
    display: flex;
    padding: 0;
    margin-bottom: 30px;
    li {
      list-style: none;
      padding: 0 20px 0 0;
      a {
        opacity: .5;
        color: #fff;
        &:hover {
          color: var(--main);
          opacity: 1;
        }
      }
    }
  }
`

const LoginPanel = ({ children }) => (
    <StyledLoginPanel>
        <StyledLoginFormWrap>
            <MDBContainer>
                <MDBRow center>
                    <MDBCol>
                        <StyledLoginFormLogo>
                            <img alt="app logo" src={logo}/>
                        </StyledLoginFormLogo>
                        { children }
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </StyledLoginFormWrap>
        <StyledLoginImage>
          <StyledLoginPanelSocials>
            <ul>
              <li><a href="https://www.facebook.com/lesnahub/" target="_blank" rel="noopener noreferrer">Facebook</a></li>
              <li><a href="https://www.instagram.com/lesnahub/" target="_blank" rel="noopener noreferrer">Instagram</a></li>
              <li><a href="https://www.linkedin.com/company/le%C5%9Bna-hub/" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
            </ul>
          </StyledLoginPanelSocials>
        </StyledLoginImage>
    </StyledLoginPanel>
)

export default LoginPanel;
