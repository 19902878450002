import React, {Component} from 'react';
import {
  MDBAlert,
  MDBBtn,
  MDBInput, MDBLink,
} from "mdbreact";
import axios from "axios";
import {backendHost} from "../../common/helpers/http";
import LoginPanel from "../Common/LoginPanel";
import styled from "styled-components";

const StyledRegisterForm = styled.form`
  h1 {
    text-align: center;
    margin-bottom: 100px;
    font-weight: bold;
    font-size: 2.6em;
    p {
      font-size: 16px;
      opacity: .7;
      font-weight: 400;
      margin-top: 10px;
    }
  }
  input {
    border-radius: 50px !important;
    padding: 12px 0 12px 20px !important;
    width: calc(100% - 20px);
    background-color: #F1F2F8 !important;
  }
  label {
    left: 20px;
  }
  button {
    width: 100%;
    margin: 0;
  }
`

const StyledRegisterFormLogin = styled.div`
 padding: 20px 0;
 margin-top: 40px;
 border-top: 1px solid rgba(0,0,0,0.1); 
 text-align: center;
 opacity: .6;
 a {
  padding: 0 0 0 5px;
  font-weight: 600;
  font-size: .9em;
  overflow: inherit;
  display: inline-block;
  &:hover {
    color: var(--main);
    opacity: 1;  
  }
 }
`
const LHButton = styled(MDBBtn)`
  background-color: var(--main) !important;
  border-radius: 50px;
`

export class ChangePasswordPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: null,
      showConfirmEmailMsg: false,
      showPasswordSetMsg: false,
      errorMsg: null,
      passwordErrorMsg: null,
      code: null,
      password: null,
      passwordRepeat: null,
    };
    this.handleFormSubmit= this.handleFormSubmit.bind(this);
  }

  handleFormSubmit(e) {
    e.preventDefault();
    this.state.code ? this.submitPasswordForm() : this.submitForm();
  }

  componentDidMount() {
    const {code} = this.props.match.params;
    if (code) {
      this.setState({code});
    }
  }

  async submitForm() {
    const {username} = this.state;
    if (!username) {
      this.setState({errorMsg: 'EMAIL_EMPTY'});
      return;
    }
    try {
      await axios.post(`${backendHost}/user/changePassword`, {username});
      this.setState({showConfirmEmailMsg: true, errorMsg: null});
    } catch (error) {
      const response = error.response.data;
      this.setState({errorMsg: response.message});
    }
  }

  async submitPasswordForm() {
    const {password, passwordRepeat, code} = this.state;
    if (!password || !passwordRepeat || password !== passwordRepeat) {
      this.setState({passwordErrorMsg: 'PASSWORD_REPEAT'});
      return;
    }
    try {
      await axios.post(`${backendHost}/user/setPassword`, {code, password});
      this.setState({showPasswordSetMsg: true, passwordErrorMsg: null});
    } catch (error) {
      const response = error.response.data;
      this.setState({passwordErrorMsg: response.message});
    }
  }

  render() {
    const {code, errorMsg, passwordErrorMsg, showConfirmEmailMsg, showPasswordSetMsg} = this.state;

    return (
      <LoginPanel>
        <StyledRegisterForm onSubmit={this.handleFormSubmit}>
          <h1>
            Zmiana hasła
          </h1>

          {(errorMsg || showConfirmEmailMsg) &&
          <MDBAlert color="info">
            Wiadomość z linkiem do zmiany hasła została wysłana na podany adres email
          </MDBAlert>
          }
          {passwordErrorMsg &&
          <MDBAlert color="danger">
            Podane hasła nie są identyczne
          </MDBAlert>
          }

          {showPasswordSetMsg &&
          <MDBAlert color="info">
            Hasło zostało zmienione. <MDBLink to="/" className="alert-link" style={{display: 'inline', padding: '0 0 0 5px'}}>Zaloguj się</MDBLink>.
          </MDBAlert>
          }

          {!showConfirmEmailMsg && !showPasswordSetMsg && code &&
          <>
            <div className="grey-text">
              <p className="text-center">Wprowadź nowe hasło</p>
              <MDBInput
                label="Hasło"
                group
                type="password"
                onChange={(e) => this.setState({password: e.currentTarget.value})}
                validate
              />
              <MDBInput
                label="Powtórz hasło"
                group
                type="password"
                onChange={(e) => this.setState({passwordRepeat: e.currentTarget.value})}
                validate
              />
            </div>
            <div className="text-center">
              <LHButton type="submit">Dalej</LHButton>
            </div>
          </>
          }

          {!showConfirmEmailMsg && !code && !errorMsg &&
          <>
            <div className="grey-text">
              <p className="text-center">Podaj swój adres e-mail. <br/> Otrzymasz link pod którym możliwa będzie zmiana hasła.</p>
              <MDBInput
                label="Adres e-mail"
                group
                type="email"
                validate
                error="wrong"
                success="right"
                onChange={(e) => this.setState({username: e.currentTarget.value})}
              />
            </div>
            <div className="text-center">
              <LHButton type="submit">Dalej</LHButton>
            </div>
          </>
          }
        </StyledRegisterForm>
        <StyledRegisterFormLogin>
          <MDBLink to="/">Wróć</MDBLink>
        </StyledRegisterFormLogin>
      </LoginPanel>
    )
  }
}
