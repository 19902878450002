import axios from "axios";
import {backendHost} from "../../common/helpers/http";
import {requestErrorHandler} from "../../common/helpers/errorHandler";

export const openDoor = (type, data) => async (dispatch, getState) => {
    try {
        const state = getState();
        const response = await axios.post(`${backendHost}/auth/openDoors`, {
            type: type,
            data: data,
        }, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return {
            open: true,
            message: "Wszystko OK",
            state: 'info',
            submitting: false,
        }

    } catch (error) {
        return {
            open: false,
            message: "Błąd",
            state: 'error',
            submitting: false,
            error: error.response.data.message
        }
    }
};

// export const openDoor = () => async (dispatch, getState) => {
//     try {
//         const state = getState();
//         const accessToken = state.user.accessToken;
//         const response = await axios.get(`https://eu-apia.coolkit.cc/v2/smartscene2/webhooks/execute?id=5509cf89fc614434b084511a1d19aa0e`, {
//             accessToken
//         }, {
//             headers: {
//                 'Access-Control-Request-Method': 'GET',
//                 'Content-Type': 'application/json',
//             }
//         });
//         return {
//             open: true,
//             message: "Wszystko OK",
//             state: 'info'
//         }
//
//     } catch (error) {
//         requestErrorHandler(error);
//         throw error.response;
//     }
// }
