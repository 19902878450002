import React from 'react';
import { MDBCardTitle, MDBCardText } from 'mdbreact';
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import {LHButtonOutline} from "../../../theme/globalStyles";
import * as Styled from '../Rooms.style'

const RoomTile = ({id, name, description, image, price, children, details}) => {
  return (
    <>
      <Styled.RoomImage bg={ image }>
        <Styled.PlanPrice>
          <strong>{ price } zł / godzina</strong>
        </Styled.PlanPrice>
      </Styled.RoomImage>
      <Styled.RoomDetails>
        <Styled.RoomHeader>
        <Styled.TileTitle>
          <div>
            <MDBCardTitle>{ name }</MDBCardTitle>
            <MDBCardText>
              { description }
            </MDBCardText>
          </div>
          {!details &&
          <div>
            <NavLink exact={true} to={`/rooms/${id}`} activeClassName="active">
              <LHButtonOutline>Rezerwuj</LHButtonOutline>
            </NavLink>
          </div>
          }
        </Styled.TileTitle>
        </Styled.RoomHeader>
        <div>
          {children}
        </div>
      </Styled.RoomDetails>
    </>
  )
}

RoomTile.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  photo: PropTypes.string,
};

export default RoomTile;
