import React, {Component} from 'react';
import BreadcrumSection from "../Common/BreadcrumSection";
import {DayWeekDeskBookingModal} from "./components/DayWeekDeskBookingModal";
import {DeskSchedulerComponent} from "./components/SchedulerComponent";
import {MonthYearDeskBooking} from "./components/MonthYearDeskBookingComponent";
import PropTypes from "prop-types";
import * as Styled from "./Desks.style";
import DesksPlans from './DesksPlans.list';
import DeskPlanTile from './components/DeskPlanTile';

export class DesksPage extends Component {

  updateSelectedPackage(value){
    const {updateSelectedPackage} = this.props;
    updateSelectedPackage(value);
  }

  render(){
    const {
      isDailyDeskBookingModalVisible,
      selectedPackage
    } = this.props;

    return (
      <>
        <BreadcrumSection
          title="Biurka"
          subtitle="Zarezerwuj biurko w naszym coworkingu"
          icon="lemon"
        />
        <Styled.Tiles>
          {DesksPlans.map(plan => {
            if (this.props.selectedPackage == null || this.props.selectedPackage === plan.plan) {
              return(
                <Styled.Tile key={plan.id} promoted={plan.promoted}>
                  { this.props.selectedPackage === plan.plan &&
                  <Styled.BackButtonWrap>
                    <Styled.BackButton onClick={() => this.updateSelectedPackage(null)}>
                      <i className="fas fa-long-arrow-alt-left"></i>
                      Zmień plan
                    </Styled.BackButton>
                  </Styled.BackButtonWrap>
                  }
                  {plan.promoted && <Styled.TileSale>-20%</Styled.TileSale>}
                  <DeskPlanTile
                    id={plan.id}
                    price={plan.price}
                    name={plan.name}
                    description={plan.description}
                    perks={plan.perks}
                    promoted={plan.promoted}
                    pickPlan={() => this.updateSelectedPackage(plan.plan)}
                    isPicked={this.props.selectedPackage === plan.plan}
                  >
                  </DeskPlanTile>
                  {(selectedPackage === 'day' || selectedPackage === 'week') && (
                    <Styled.CalendarView>
                      <DeskSchedulerComponent plan={plan.plan}/>
                      {isDailyDeskBookingModalVisible && <DayWeekDeskBookingModal plan={plan.plan}/>}
                    </Styled.CalendarView>
                  )}
                  {(selectedPackage === 'year' || selectedPackage === 'month') && (
                    <MonthYearDeskBooking year plan={plan.plan}/>
                  )}
                </Styled.Tile>
              )
            }
            }
          )}
        </Styled.Tiles>


      </>
    )
  }
}

DesksPage.propTypes = {
  updateSelectedPackage: PropTypes.func,
  isDailyDeskBookingModalVisible: PropTypes.bool,
  selectedPackage: PropTypes.string,
};

