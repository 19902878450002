import {DESK_ACTIONS} from "./Desks.actions";
import {connect} from "react-redux";
import {DesksPage} from "./Desks.page";

const mapStateToProps = (state) => ({
  isDailyDeskBookingModalVisible: state.desks.booking.isDailyDeskBookingModalVisible,
  isMonthlyDeskBookingModalVisible: state.desks.booking.isMonthlyDeskBookingModalVisible,
  selectedPackage: state.desks.booking.selectedPackage,
});

const mapDispatchToProps = dispatch => {
  return {
    updateSelectedPackage: (value) => dispatch({ type: DESK_ACTIONS.UPDATE_SELECTED_PACKAGE, payload: value }),
  }
};

export const DesksContainer = connect(mapStateToProps, mapDispatchToProps)(DesksPage);
