import React, {Component} from "react";
import {MDBContainer, MDBNav, MDBNavItem, MDBNavLink, MDBRow, MDBTabContent, MDBTabPane} from "mdbreact";
import {connect} from "react-redux";
import {createMonthlyBooking, DESK_ACTIONS, getMonthlyDesksAvailability} from "../Desks.actions";
import moment from "moment";
import 'react-infinite-calendar/styles.css';
import {
  stripePublicKey,
} from "../../../common/helpers/stripe";
import {LHButton, LHInputWrap} from "../../../theme/globalStyles";
import * as Styled from "./MonthYearDeskBookingComponent.style"
import SubmitSpinner from "../../Common/SubmitSpinner";
import FormDatePicker from "../../Common/FormDatePicker";

class MonthYearDeskBookingComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookingStart: moment().format('YYYY-MM-DD'),
      bookingStartDate: moment().toDate(),
      selectedDeskId: null,
      annualContract: false,
      deskType: 'dedicated',
      bookingSummary: false,
      submitting: false,
      activeItem: "2",
    };

    this.submitForm = this.submitForm.bind(this);
    this.updateBookingStart = this.updateBookingStart.bind(this);
    this.updateBookingLength = this.updateBookingLength.bind(this);
  }

  componentDidMount() {
    const {bookingStart, bookingLength} = this.state;
    this.props.getMonthlyDesksAvailability(bookingStart, bookingLength);
    if (this.props.plan === "year") {
        this.setState({annualContract: true});
    }
  }

  async submitForm() {
    const {bookingStart, selectedDeskId, annualContract} = this.state;
    this.setState({
      submitting: true,
    });
    try {
      const response = await this.props.createMonthlyBooking(bookingStart, selectedDeskId, annualContract);
      this.redirectToPayment(response.stripeSessionId);
    } catch (e) {
      this.setState({
        submitting: false,
      });
      console.log(e);
    }
  }

  redirectToPayment(sessionId) {
    const stripe = window.Stripe(stripePublicKey);
    stripe.redirectToCheckout({
      sessionId,
    }).then(function (result) {
      console.error(result);
    });
  }

  updateBookingStart(date) {
    const bookingStart = moment(date).format('YYYY-MM-DD');
    const bookingStartDate = moment(date).toDate();
    this.setState({
      bookingStart,
      bookingStartDate,
      selectedDeskId: null,
      bookingSummary: false,
    });
    const {bookingLength} = this.state;
    this.props.getMonthlyDesksAvailability(bookingStart, bookingLength);
  }

  updateBookingLength(bookingLength) {
    this.setState({
      bookingLength,
      selectedDeskId: null
    });
    const {bookingStart} = this.state;
    this.props.getMonthlyDesksAvailability(bookingStart, bookingLength);
  }

  setDeskType(deskType) {
    this.setState({deskType});
    if(deskType !== 'dedicated'){
      this.setState({selectedDeskId: null});
    }
  }

  toggleDesk = e => {
    const deskId = e.currentTarget.getAttribute('value');
    this.setState({
      selectedDeskId: parseInt(deskId),
      bookingSummary: true,
    });
  }

  toggle = tab => e => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab
      });
    }
  };

  render() {
    const {availableDesks} = this.props;
    const deskList = [...Array(25).keys()].slice(1, 25);
    const deskStatus = desk => {
      if (desk === this.state.selectedDeskId && availableDesks.includes(desk)) {
        return "selected"
      }
      if (availableDesks.includes(desk)) {
        return "free"
      } else {
        return "busy"
      }
    }
    return (
      <>
        <Styled.BookingWrap>
          <Styled.FormWrap>
            <Styled.BookingFormFilter>
              <div>
                <Styled.FormLabel><span>1</span> Wybierz datę wynajmu</Styled.FormLabel>
                <FormDatePicker
                  selected={this.state.bookingStartDate}
                  onChange={date => this.updateBookingStart(date)}
                />
              </div>
              { this.props.plan === "month" &&
                <div>
                  <Styled.FormLabel>
                    <span>2</span> Rodzaj biurka
                  </Styled.FormLabel>
                  <LHInputWrap>
                    <select className="browser-default custom-select"
                            onChange={(e) => this.setDeskType(e.currentTarget.value) }
                    >
                      <option value="dedicated">Biurko dedykowane</option>
                      <option value="hotdesk">Biurko hot-desk</option>
                    </select>
                  </LHInputWrap>
                </div>
              }

            </Styled.BookingFormFilter>

            { (this.props.plan === "year" || this.state.deskType === "dedicated") &&
            <>
              <Styled.FormLabel>
                <span>{this.props.plan === "year" ? '2' : '3'}</span>
                Wybierz swoje biurko
              </Styled.FormLabel>
              <Styled.FormTabs>
                <MDBNav className="nav-tabs mt-5">
                  {/*<MDBNavItem>*/}
                  {/*  <MDBNavLink to="#" active={this.state.activeItem === "1"} activeClassName="active-tab" onClick={this.toggle("1")} role="tab" >*/}
                  {/*    Strefa Coworkingowa #1*/}
                  {/*  </MDBNavLink>*/}
                  {/*</MDBNavItem>*/}
                  <MDBNavItem>
                    <MDBNavLink to="#" active={this.state.activeItem === "2"} activeClassName="active-tab" onClick={this.toggle("2")} role="tab" >
                      Strefa coworkingowa  #1
                    </MDBNavLink>
                  </MDBNavItem>
                </MDBNav>
                <MDBTabContent activeItem={this.state.activeItem} >
                  {/*<MDBTabPane tabId="1" role="tabpanel">*/}


                  {/*  <Styled.BookingMap ref="deskMap">*/}
                  {/*    <Styled.BookingMapRow top>*/}
                  {/*      {*/}
                  {/*        deskList.slice(0, 5).map((desk) =>*/}
                  {/*          <Styled.BookingMapDesk*/}
                  {/*            key={desk}*/}
                  {/*            value={desk}*/}
                  {/*            data-status={deskStatus(desk)}*/}
                  {/*            data-desk-id={desk}*/}
                  {/*            onClick={this.toggleDesk}>*/}
                  {/*            {desk}*/}
                  {/*          </Styled.BookingMapDesk>*/}
                  {/*        )*/}
                  {/*      }*/}
                  {/*    </Styled.BookingMapRow>*/}
                  {/*    <Styled.BookingMapRow>*/}
                  {/*      {*/}
                  {/*        deskList.slice(5, 17).map((desk) =>*/}
                  {/*          <Styled.BookingMapDesk*/}
                  {/*            key={desk}*/}
                  {/*            value={desk}*/}
                  {/*            data-status={deskStatus(desk)}*/}
                  {/*            data-desk-id={desk}*/}
                  {/*            onClick={this.toggleDesk}>*/}
                  {/*            {desk}*/}
                  {/*          </Styled.BookingMapDesk>*/}
                  {/*        )*/}
                  {/*      }*/}
                  {/*    </Styled.BookingMapRow>*/}
                  {/*  </Styled.BookingMap>*/}
                  {/*</MDBTabPane>*/}
                  <MDBTabPane tabId="2" role="tabpanel">
                    <Styled.BookingMapNew ref="deskMap">
                      <Styled.BookingMapColumns>
                        <Styled.BookingMapColumn>
                          {
                            deskList.slice(0, 6).map((desk) =>
                              <Styled.BookingMapNewDesk
                                key={desk}
                                value={desk}
                                data-status={deskStatus(desk)}
                                data-desk-id={desk}
                                onClick={this.toggleDesk}>
                                {desk}
                              </Styled.BookingMapNewDesk>
                            )
                          }
                        </Styled.BookingMapColumn>
                        <Styled.BookingMapColumn>
                          {
                            deskList.slice(6, 11).map((desk) =>
                              <Styled.BookingMapNewDesk
                                  className="small-group"
                                key={desk}
                                value={desk}
                                data-status={deskStatus(desk)}
                                data-desk-id={desk}
                                onClick={this.toggleDesk}>
                                {desk}
                              </Styled.BookingMapNewDesk>
                            )
                          }
                        </Styled.BookingMapColumn>
                        <Styled.BookingMapColumn>
                          {
                            deskList.slice(11, 17).map((desk) =>
                              <Styled.BookingMapNewDesk
                                key={desk}
                                value={desk}
                                data-status={deskStatus(desk)}
                                data-desk-id={desk}
                                onClick={this.toggleDesk}>
                                {desk}
                              </Styled.BookingMapNewDesk>
                            )
                          }
                        </Styled.BookingMapColumn>
                        <Styled.BookingMapColumn>
                          {
                            deskList.slice(17, 23).map((desk) =>
                              <Styled.BookingMapNewDesk
                                key={desk}
                                value={desk}
                                data-status={deskStatus(desk)}
                                data-desk-id={desk}
                                onClick={this.toggleDesk}>
                                {desk}
                              </Styled.BookingMapNewDesk>
                            )
                          }
                        </Styled.BookingMapColumn>
                      </Styled.BookingMapColumns>
                      <Styled.BookingMapColumns className="alone-desk">
                        <Styled.BookingMapColumn>
                          {
                            deskList.slice(23, 24).map((desk) =>
                                <Styled.BookingMapNewDesk
                                    className="alone-desk"
                                    key={desk}
                                    value={desk}
                                    data-status={deskStatus(desk)}
                                    data-desk-id={desk}
                                    onClick={this.toggleDesk}>
                                  {desk}
                                </Styled.BookingMapNewDesk>
                            )
                          }
                        </Styled.BookingMapColumn>
                      </Styled.BookingMapColumns>

                    </Styled.BookingMapNew>
                  </MDBTabPane>
                </MDBTabContent>
              </Styled.FormTabs>

            <Styled.BookingMapLegend>
              <Styled.BookingLegendItem status="busy">Zajęte</Styled.BookingLegendItem>
              <Styled.BookingLegendItem status="free">Wolne</Styled.BookingLegendItem>
              <Styled.BookingLegendItem status="selected">Wybrane</Styled.BookingLegendItem>
            </Styled.BookingMapLegend>
            </>
            }
            {(this.state.bookingSummary || this.state.deskType === "hotdesk") &&
            <Styled.BookingSummary>
              <div>
                <Styled.FormLabel>
                  <span>{(this.props.plan === "year" || this.state.deskType === "hotdesk") ? '3' : '4'}</span>
                  Podsumowanie</Styled.FormLabel>
                <div>Wybrane biurko: <strong>{this.state.selectedDeskId ? this.state.selectedDeskId : "brak"}</strong>
                </div>
                <div>Data rozpoczęcia: <strong>{this.state.bookingStart}</strong></div>
              </div>
              <MDBRow>
                <div>
                  <LHButton onClick={this.submitForm}>Przejdź do płatności</LHButton>
                </div>
              </MDBRow>
            </Styled.BookingSummary>
            }
            { this.state.submitting &&
            <SubmitSpinner />
            }
          </Styled.FormWrap>
        </Styled.BookingWrap>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  availableDesks: state.desks.monthlyAvailability,
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  return {
    showModal: (startDate, selectedDeskId) => dispatch({
      type: DESK_ACTIONS.SHOW_MONTHLY_DESK_BOOKING_MODAL,
      payload: {startDate, selectedDeskId}
    }),
    getMonthlyDesksAvailability: (bookingStart) =>
      dispatch(getMonthlyDesksAvailability(bookingStart)),
    createMonthlyBooking: (bookingStart, deskId, annualContract) =>
      dispatch(createMonthlyBooking(bookingStart, deskId, annualContract)),
  }
};

export const MonthYearDeskBooking = connect(mapStateToProps, mapDispatchToProps)(MonthYearDeskBookingComponent);
