import {connect} from "react-redux";
import VirtualOfficePage from "./VirtualOffice.page";
import {submitMessage} from "../VirtualOffice/VirtualOffice.actions";

const mapDispatchToProps = dispatch => {
  return {
    submitMessage: (plan, subject, message) => dispatch(submitMessage(plan, subject, message)),
  }
};

export const VirtualOfficeContainer = connect(null, mapDispatchToProps)(VirtualOfficePage);
