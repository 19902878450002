import axios from "axios";
import {backendHost} from "../../common/helpers/http";
import {requestErrorHandler} from "../../common/helpers/errorHandler";

export const submitMessage = (subject, message) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {accessToken} = state.user;
    const response = await axios.post(`${backendHost}/contact`, {
      subject,
      message,
    }, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });
  } catch (error) {
    requestErrorHandler(error);
    throw error.response.data;
  }
};
