import styled from "styled-components";

export const LoginForm = styled.form`
  h1 {
    text-align: center;
    margin-bottom: 100px;
    font-weight: bold;
    font-size: 2.6em;
    @media(max-width: 1000px) {
      font-size: 1.8em;
    }
    @media(max-width: 720px) {
      font-size: 1.4em;
    }
  }
  input {
    border-radius: 50px !important;
    padding: 12px 0 12px 20px !important;
    width: calc(100% - 20px);
    background-color: #F1F2F8 !important;
  }
  label {
    left: 20px;
  }
  button {
    width: 100%;
    margin: 0;
  }
`
export const LoginFormRegister = styled.div`
 padding: 20px 0;
 margin-top: 40px;
 border-top: 1px solid rgba(0,0,0,0.1); 
 text-align: center;
 opacity: .6;
 a {
  padding: 0 0 0 5px;
  font-weight: 600;
  font-size: .9em;
  overflow: inherit;
  display: inline-block;
  &:hover {
    color: var(--main);
    opacity: 1;  
  }
 }
`

export const LoginFormPasswordRemember = styled.div`
 margin-top: 20px;
 text-align: center;
 opacity: .6;
 a {
  font-size: .8em;
  padding: 0 0 0 5px;
  overflow: inherit;
  display: inline-block;
  &:hover {
    color: var(--main);
    opacity: 1;  
  }
 }
`

export const AlertWrap = styled.div`
  margin-bottom: 40px;
`
