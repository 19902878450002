import {connect} from "react-redux";
import {RegistrationPage} from "./Registration.page";

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = dispatch => {
  return {

  }
};

export const RegistrationContainer = connect(mapStateToProps, mapDispatchToProps)(RegistrationPage);
