import React, {Component} from 'react';
import BreadcrumSection from "../Common/BreadcrumSection";
import * as Styled from "../DoorsTabletContainer/Doors.style"
import OpenDoorsLoader from "../Common/OpenDoorsLoader";
import { WallDoorsButton } from "../../theme/globalStyles";

class DoorsPage extends Component {

  constructor() {
    super();
    this.state = {
      open: false,
      message: 'Kliknij aby otworzyć drzwi wejściowe',
      state: 'info',
      error: null,
      submitting: false,
    };
    this.handleScan = this.handleScan.bind(this);
  }

  openDoors() {
    this.setState({
      ...this.state,
      submitting: true,
    })

    const {openDoor} = this.props;
    openDoor('TABLET').then((value) => this.setState((value)));

    setTimeout(() => {
        this.setState({
          open: false,
          message: 'Kliknij aby otworzyć drzwi wejściowe',
          state: 'ok',
          error: null,
          submitting: false,
        })
      }
      , 20000);
  }

  handleScan(data) {
    const {submitting} = this.state;
    if (submitting) {
      return
    }

    if(data) {

      this.setState({
        ...this.state,
        submitting: true,
      })

      const {openDoor} = this.props;
      openDoor('QR', data).then((value) => this.setState((value)));

      setTimeout(() => {
          this.setState({
            open: false,
            message: 'Kliknij aby otworzyć drzwi wejściowe',
            state: 'ok',
            submitting: false,
            error: null,
          })
        }
        , 20000);

    } else {
      return false
    }
  };

  handleError(err) {
    console.error(err);
  };

  render() {
    const { open, message, error, submitting} = this.state;

    return (
      <>
        <Styled.HeaderWrap>
          <BreadcrumSection
            title="LeśnaHub"
            subtitle="Panel na ścianie"
            icon="building"
          />
        </Styled.HeaderWrap>
        <Styled.WallTiles>

          <Styled.Tile>
            <div>
              <p>Rezerwacje Sal</p>
            </div>
            <iframe
              src="https://calendar.google.com/calendar/embed?height=450&wkst=1&bgcolor=%23877bed&ctz=Europe%2FWarsaw&showPrint=0&showCalendars=0&showTz=0&showTabs=1&showTitle=0&mode=AGENDA&src=Z29ybWFuZXQucGxfMmQ1c3VvcGdzY2xjN3AzN2NqZXVjbjBzZDRAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=cGwucG9saXNoI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&color=%233F51B5&color=%23125a12"
              height="450" frameBorder="0" scrolling="no"></iframe>
            <Styled.Socials>
              <ul>
                <li><a href="tel:+48603112681">Połącz z Biurem</a></li>
                <li><a href="https://www.facebook.com/lesnahub/" target="_blank" rel="noopener noreferrer">Facebook</a>
                </li>
                <li><a href="https://www.instagram.com/lesnahub/" target="_blank"
                       rel="noopener noreferrer">Instagram</a></li>
                <li><a href="https://www.linkedin.com/company/le%C5%9Bna-hub/" target="_blank"
                       rel="noopener noreferrer">LinkedIn</a></li>
              </ul>
            </Styled.Socials>
          </Styled.Tile>
          <Styled.Tile>
            <Styled.OpenContainer>
              {submitting &&
                <OpenDoorsLoader />
              }
              {open && (
                <>
                  <Styled.SendWrap>
                    <Styled.SendIcon/>
                    <h3>Drzwi są otwarte przez 20 sekund</h3>
                    <p>Pociągnij mocno za klamkę!</p>
                  </Styled.SendWrap>
                </>
              )}

              {error === 'TOKEN EXPIRED' &&
                <Styled.QrCodeError>Twój QR kod stracił ważność. Zaloguj się ponownie aby wygenerować nowy</Styled.QrCodeError>
              }
              {error === 'USER INACTIVE' &&
                <Styled.QrCodeError>Twoje konto jest nieaktywne. Skontaktuj się z administratorem aby uzyskać więcej informacji.</Styled.QrCodeError>
              }
              {error === 'UNKNOWN CODE' &&
                <Styled.QrCodeError>Nie możemy rozpoznać Twojego QR kodu. Upewnij się, że korzystasz ze swojego indywidualnego kodu, na swoim koncie użytkownika.</Styled.QrCodeError>
              }
              {!open && (
                <Styled.Form onSubmit={(e) => e.preventDefault()}>
                  <div className="text-center mt-4">
                    <WallDoorsButton type="submit" onClick={() => this.openDoors()}>
                      Otwórz drzwi
                    </WallDoorsButton>
                  </div>
                </Styled.Form>
              )}
            </Styled.OpenContainer>
          </Styled.Tile>
        </Styled.WallTiles>
      </>
    )
  }
}
export default DoorsPage;
