import {ROOM_ACTIONS} from "./Rooms.actions";

export const RoomsReducer = (state = {
  data: [],
  bookings: [],
  booking: {
    isModalVisible: false,
    id: null,
    name: null,
    date: null,
  }
}, action) => {
  switch (action.type) {
    case ROOM_ACTIONS.UPDATE_ROOMS_DATA:
      return {...state, data: action.payload};
    case ROOM_ACTIONS.UPDATE_ROOM_BOOKINGS:
      return {...state, bookings: action.payload};
    case ROOM_ACTIONS.SET_ROOM_ID:
      return {...state, booking: { ...state.booking, id: action.payload.id }};
    case ROOM_ACTIONS.SET_BOOKING_DATE:
      return {...state, booking: { ...state.booking, date: action.payload.date }};
    case ROOM_ACTIONS.SHOW_ROOM_MODAL:
      return {...state, booking: { ...state.booking, isModalVisible: true }};
    case ROOM_ACTIONS.HIDE_ROOM_MODAL:
      return {...state, booking: { ...state.booking, isModalVisible: false }};
    default:
      return state;
  }
};
