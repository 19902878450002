import {DESK_ACTIONS} from "./Desks.actions";

export const DesksReducer = (state = {
  dailyAvailability: [],
  monthlyAvailability: [],
  booking: {
    isDailyDeskBookingModalVisible: false,
    isMonthlyDeskBookingModalVisible: false,
    selectedPackage: null,
    startDate: null,
    selectedDeskId: null,
  }
}, action) => {
  switch (action.type) {
    case DESK_ACTIONS.UPDATE_DAILY_DESKS_AVAILABILITY:
      return {...state, dailyAvailability: action.payload};
    case DESK_ACTIONS.UPDATE_MONTHLY_DESKS_AVAILABILITY:
      return {...state, monthlyAvailability: action.payload};
    case DESK_ACTIONS.SHOW_DAILY_DESK_BOOKING_MODAL:
      return {...state, booking: { ...state.booking, isDailyDeskBookingModalVisible: true, startDate: action.payload }};
    case DESK_ACTIONS.SHOW_MONTHLY_DESK_BOOKING_MODAL:
      return {...state,
        booking: {
        ...state.booking,
          isMonthlyDeskBookingModalVisible: true,
          startDate: action.payload.startDate,
          selectedDeskId: action.payload.selectedDeskId,
      }};
    case DESK_ACTIONS.HIDE_DAILY_DESK_BOOKING_MODAL:
      return {...state, booking: { ...state.booking, isDailyDeskBookingModalVisible: false, startDate: null }};
    case DESK_ACTIONS.HIDE_MONTHLY_DESK_BOOKING_MODAL:
      return {...state, booking: { ...state.booking, isMonthlyDeskBookingModalVisible: false, startDate: null }};
    case DESK_ACTIONS.UPDATE_SELECTED_PACKAGE:
      return {...state, booking: { ...state.booking, selectedPackage: action.payload}};
    default:
      return state
  }
}
