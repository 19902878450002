import {CALENDAR_ACTIONS} from "./Calendar.actions";

export const CalendarReducer = (state = {
  personalBookings: [],
  personalStats: null,
}, action) => {
  switch (action.type) {
    case CALENDAR_ACTIONS.UPDATE_PERSONAL_BOOKINGS:
      return {...state, personalBookings: action.payload};
    case CALENDAR_ACTIONS.UPDATE_PERSONAL_STATS:
      return {...state, personalStats: action.payload};
    default:
      return state;
  }
};
