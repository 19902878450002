import styled from "styled-components";

export const ModalWrap = styled.div`
 .modal-dialog {
    top: calc(50% - 30px);
    transform: translateY(-50%) !important;
 } 
 .modal-body {
    padding: 30px;
 }
 .modal-header {
  padding: 20px 30px;
 }
 .modal-title {
    font-size: 18px;
    font-weight: bold;
 }
`
export const TextareaWrap = styled.div`
  input, textarea {
    padding: 12px 0 12px 20px !important;
    width: calc(100% - 20px);
    background-color: #F1F2F8 !important;
    height: auto !important;
    border: none;
    border-bottom: 1px solid #ced4da !important;
  }
  input {
    border-radius: 50px !important;
  }
  textarea {
    border-radius: 12px !important;
  }
  label {
    left: 20px;
  }
` 

export const ModalForm = styled.div`
  display: flex;
  border-radius: 4px;
  padding: 20px 0;
  & > div {
    flex: 1;
  }
  label {
    left: 20px;
  }
`
export const ModalInfo = styled.div`
  opacity: .6;
`
export const FormLabel = styled.div`
  margin-bottom: 20px;
  span {
    display: inline-block;
    height: 26px;
    width: 26px;
    margin-right: 5px;
    background-color: #E6E3FC;
    color: var(--main);
    font-size: .9em;
    border-radius: 50px;
    text-align: center;
    line-height: 26px;
  }
`
