import {connect} from "react-redux";
import {LoginPage} from "./Login.page";

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = dispatch => {
  return {

  }
};

export const LoginContainer = connect(mapStateToProps, mapDispatchToProps)(LoginPage);
