import React, {Component} from 'react';
import BreadcrumSection from "../Common/BreadcrumSection";
import RoomTile from "./components/RoomTile";
import * as Styled from './Rooms.style';
import Rooms from './Rooms.list';
import moment from "moment";
import {LHButtonSmall} from "../../theme/globalStyles";


export class RoomsPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showNotes: false,
    };
  }
  
  componentDidMount() {
    const {getPersonalBookings} = this.props;
    getPersonalBookings(moment().format('YYYY'), moment().format('MM'));
  };

  async handleBookingRemove(id){
    if(!window.confirm('Czy na pewno chcesz usunąć tą rezerwację?')) return false;
    const {removeRoomBooking} = this.props;
    await removeRoomBooking(id);
    this.updateList();
  }

  async updateList(){
    const {getPersonalBookings} = this.props;
    getPersonalBookings(moment().format('YYYY'), moment().format('MM'));
  }


  render() {
    const {personalBookings, removeRoomBooking} = this.props;
    const incomingReservations = personalBookings.filter(booking => booking.itemType === 'ROOM' && moment(booking.bookingEnd).isAfter());
    const pastReservations = personalBookings.filter(booking => booking.itemType === 'ROOM' && moment(booking.bookingEnd).isBefore());

    return (
      <>
        <BreadcrumSection
          title="Sale szkoleniowe"
          subtitle="Zarezerwuj salę na swoje spotkanie biznesowe"
          icon="square"
        />
        <Styled.RoomsWrap>
          <Styled.Tiles>


            {Rooms.map(room => (
              <Styled.Tile key={room.id}>
                <RoomTile
                  id={ room.id }
                  price={ room.price }
                  image={ room.image }
                  name={ room.name }
                  description={ room.description }
                >
                  <Styled.TilePerks>
                    <ul>
                      { room.perks.map((perk, index) => (
                        <Styled.PlanPerk key={ index }>
                          { perk.name }
                        </Styled.PlanPerk>
                      ))}
                    </ul>
                  </Styled.TilePerks>
                </RoomTile>
              </Styled.Tile>
            ))}
          </Styled.Tiles>
          <Styled.MyReservations>
            <div className="card-title">Moje rezerwacje</div>
            <div>
              <h6>Nadchodzące</h6>

              {incomingReservations.map(item =>
                <div className="reservation-tile">
                  <div>Pokój: <strong>{item.itemId}</strong></div>
                  <div>
                    Data: <strong>{moment(item.bookingStart).format('DD-MM-YYYY')}</strong>
                  </div>
                  <div>
                    Godziny: <strong>{moment(item.bookingStart).format('HH:mm')} - {moment(item.bookingEnd).format('HH:mm')}</strong>
                  </div>
                  {this.state.showNotes &&
                  <div>
                    <hr/>
                    Uwagi: {item.notes}
                  </div>
                  }
                  <Styled.MyReservationsButtons>
                    <div>
                      {item.notes &&
                      <LHButtonSmall onClick={() => this.setState({showNotes: !this.state.showNotes})}>Uwagi</LHButtonSmall>
                      }
                    </div>
                    <LHButtonSmall onClick={() => this.handleBookingRemove(item.id)}>Usuń</LHButtonSmall>
                  </Styled.MyReservationsButtons>
                </div>
              )}
            </div>
            <div>
              <h6>Poprzednie</h6>
              {pastReservations.slice(0,3).map(item =>
                <div className="reservation-tile past">
                  <div>Pokój: <strong>{item.itemId}</strong></div>
                  <div>
                    Data: <strong>{moment(item.bookingStart).format('DD-MM-YYYY')}</strong>
                  </div>
                  <div>
                    Godziny: <strong>{moment(item.bookingStart).format('HH:mm')} - {moment(item.bookingEnd).format('HH:mm')}</strong>
                  </div>
                </div>
              )}
            </div>
          </Styled.MyReservations>
        </Styled.RoomsWrap>
      </>
    )}
}
