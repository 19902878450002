import React, {Component} from 'react';
import {
  MDBAlert,
  MDBInput, MDBLink,
} from "mdbreact";
import axios from "axios";
import { LHButton} from "../../theme/globalStyles";
import LoginPanel from "../Common/LoginPanel";
import {backendHost} from "../../common/helpers/http";
import * as Styled from "./Login.style";

export class LoginPage extends Component {

  constructor(props){
    super(props);
    this.state = {
      username: null,
      password: null,
      badCredentials: false,
      notActivated: false,
    }
    this.handleFormSubmit= this.handleFormSubmit.bind(this);
  }

  handleFormSubmit(e) {
    e.preventDefault();
    this.submitForm();
  }

  async submitForm(){
    const { username, password } = this.state;

    this.setState({ badCredentials: false, notActivated: false });
    try{
      const response = await axios.post(`${backendHost}/user/login`, {
        username, password
      });
      window.localStorage.setItem('accessToken', response.data.access_token);
      window.location.href = '/doors';
    }catch(error){
      const { status } = error.response;
      if(status === 401) {
        this.setState({ badCredentials: true });
      }else if(status === 503){
        this.setState({ notActivated: true });
      }
    }
  }

  render() {

    const {badCredentials, notActivated} = this.state;

    return (
        <LoginPanel>
          <Styled.LoginForm onSubmit={this.handleFormSubmit}>
            <h1>Witamy ponownie</h1>

            {badCredentials &&
            <Styled.AlertWrap>
              <MDBAlert color="danger">
                Podano niewłaściwe dane logowania.
              </MDBAlert>
            </Styled.AlertWrap>
            }

            {notActivated &&
            <Styled.AlertWrap>
              <MDBAlert color="danger">
                Twoje konto nie zostało aktywowane. Prosimy sprawdzić pocztę e-mail w celu uzyskania
                linku aktywacyjnego.
              </MDBAlert>
            </Styled.AlertWrap>
            }

            <div className="grey-text">
              <MDBInput
                  label="Adres e-mail"
                  group
                  type="email"
                  validate
                  error="wrong"
                  success="right"
                  onChange={(e) => this.setState({ username: e.currentTarget.value })}
              />
              <MDBInput
                  label="Hasło"
                  group
                  type="password"
                  onChange={(e) => this.setState({ password: e.currentTarget.value })}
                  validate
              />
            </div>
            <LHButton type="submit">Zaloguj się</LHButton>
            <Styled.LoginFormPasswordRemember>
              <MDBLink to="/change-password">Nie pamiętam hasła</MDBLink>
            </Styled.LoginFormPasswordRemember>

          </Styled.LoginForm>
          <Styled.LoginFormRegister>
            <span>Nie masz jeszcze konta?</span>
            <MDBLink to="/register">Zarejestruj się</MDBLink>
          </Styled.LoginFormRegister>
        </LoginPanel>
    )
  }
}
