import styled, { createGlobalStyle } from "styled-components";
import {MDBBtn} from "mdbreact";

export const GlobalStyle = createGlobalStyle`
    :root {
      --main: #877BED;
      --text: #373169;
    }
    body {
      background-color: ${({ theme }) => theme.body};
      color: ${({ theme }) => theme.text};
      transition: all 0.25s linear;
    }

    .card {
      box-shadow: none;
      background-color: ${({ theme }) => theme.background};
    }
`

export const LHButton = styled(MDBBtn)`
  background-color: var(--main) !important;
  border: 2px solid var(--main) !important;
  color: #fff !important;
  border-radius: 50px;
  &:not([disabled]):not(.disabled):active {
    background-color: var(--main) !important;
  }
  @media(max-width: 1100px)  {
   padding: .5rem 1rem !important; 
  }
`


export const DoorsButton = styled(MDBBtn)`
  background-color: var(--main) !important;
  border: 2px solid var(--main) !important;
  color: #fff !important;
  border-radius: 10px;
  height: 150px;
  &:not([disabled]):not(.disabled):active {
    background-color: var(--main) !important;
  }
  @media(max-width: 1100px)  {
   padding: .5rem 1rem !important; 
  }
`

export const WallDoorsButton = styled(MDBBtn)`
  background-color: var(--main) !important;
  border: 2px solid var(--main) !important;
  color: #fff !important;
  border-radius: 10px;
  height: 450px;
  font-size: 50px;
  margin-top: 10px !important;
  &:not([disabled]):not(.disabled):active {
    background-color: var(--main) !important;
  }
  @media(max-width: 1100px)  {
   padding: .5rem 1rem !important; 
  }
`


export const LHButtonSmall = styled(MDBBtn)`
  background-color: var(--main) !important;
  border: 2px solid var(--main) !important;
  color: #fff !important;
  border-radius: 50px;
  height: 30px;
  padding: 0 8px;
  &:not([disabled]):not(.disabled):active {
    background-color: var(--main) !important;
  }
  @media(max-width: 1100px)  {
   padding: .5rem 1rem !important;
  }
`

export const LHButtonOutline = styled(MDBBtn)`
  background-color: transparent !important;
  border: 2px solid var(--main) !important;
  color: var(--main) !important;
  border-radius: 50px;
  &:not([disabled]):not(.disabled):active {
    background-color: transparent !important;
  }
  @media(max-width: 1100px)  {
   padding: .5rem 1rem !important;
  }
`
export const LHInputWrap = styled.div`
  input, select {
    border-radius: 50px !important;
    padding: 12px 0 12px 20px !important;
    width: calc(100% - 20px);
    height: auto !important;
    border: none;
    border-bottom: 1px solid #ced4da !important;
    border-bottom: 1px solid ${props => props.validation ? '#F6423C !important' : ''};
    background-color: ${props => props.validation ? 'rgba(246, 66, 60, .2) !important' : '#f1f2f8 !important'};
  }
`;
export const LHDatePickerWrap = styled.div`
  position: relative;
  z-index: 20;
  .react-datepicker-wrapper {
    width: 100%;
    margin-right: 20px;
  }
  input {
    width: calc(100% - 20px);
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem 1.75rem .375rem .75rem;
    color: rgba(0,0,0,0.65);
  }
`
